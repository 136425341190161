import { Navigate  } from "react-router-dom";
import { E404 } from "../404/404";
import { FulllayoutMainWeb } from "./Layout";
import { Home } from "../Home/Home";
import { ContactUs } from "../ContactUs/contactus";
import { PrivacyPolicy } from "../OtherPages/privicyPolicy";
import { Terms } from "../OtherPages/terms";
export const ThemeRouteWeb = [
  {
    path: "/",
    element: <FulllayoutMainWeb />,
    children: [
      { path: "/",  element: <Home />  },
      { path: "ContactUs",  element: <ContactUs /> },      
      { path: "Privacy",  element: <PrivacyPolicy /> },      
      { path: "Terms",  element: <Terms /> },      
      { path: "*", element: <E404 /> },
    ],
  },
];

