import React from "react"
import { Link, useLocation } from "react-router-dom"
import styles from "./breadcump.module.css"
import { EnvelopeIcon , PhoneIcon } from "@heroicons/react/24/solid";

export function Breadcrumb() {
    const location = useLocation();
    return (<>
        <div className={`  z-50  ${styles.breadcrumbcontainer}  fixed  ${styles.scaleinvertop}  `}
        >
            <div className="flex items-center justify-between flex-wrap gap-2 bg-white sm:place-items-center place-items-stretch    shadow-lg border border-gray-200 ">

                <div className="col-span-1 ">
                    <div className="flex flex-1 items-center  justify-center    ">

                        <Link to={'/'} className="flex flex-shrink-0 mx-1 items-center">
                            <img
                                title="Facebook"
                                className="h-16 w-28 "
                                src="/LegendMoverslogo.png"
                                alt="Your Company"
                            />
                        </Link>
                    </div>

                </div>
                <div className="col-span-1 md:hidden flex items-center justify-between px-3">
                    <div className="relative mx-1  rounded-3xl text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
                        <a
                            href="//api.whatsapp.com/send?phone=+971564683285&amp;text=Hello Sir, I want to avail Legend Movers Services"
                            title="view "
                            className="relative block   p-1 text-black text-green-600 hover:text-green-300  "
                        >
                            <span className="absolute-inset-1.5" />
                            <span className="sr-only">whatsapp</span>

                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6 bi bi-whatsapp" viewBox="0 0 16 16">
                                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                            </svg>                                    </a>

                    </div>
                    <div className="relative mx-1  rounded-3xl p-1 text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
                        <a
                            href="tel:+971567560210"
                            title="view "

                            className="relative block   p-1 text-black   "
                        >
                            <span className="absolute-inset-1.5" />
                            <span className="sr-only">Call</span>

                            <PhoneIcon className="h-6 w-6" aria-hidden="true" />
                        </a>

                    </div>
                    <div className="relative  mx-1  rounded-3xl p-1 text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
                        <a
                            href="mailto:support@legendmovers.co?subject='Contact to Legend Mover through Web Email'&body='I want to Avail your services'"
                            title="Send Email"

                            className="relative block   p-1 text-black   "
                        >
                            <span className="absolute-inset-1.5" />
                            <span className="sr-only">Send Email</span>
                            <EnvelopeIcon className="h-6 w-6" aria-hidden="true" />
                        </a>

                    </div>
                </div>
                <nav
                    data-aos={`fade-down`}
                    className={`    col-span-3  flex justify-center px-1 text-gray-700  rounded-sm bg-white   text-center items-center `}
                    aria-label="Breadcrumb"     >

                    <ol className="grid grid-cols-3 place-items-center gap-2 w-full ">

                        <li className="inline-flex items-center col-span-1 p-2 cursor-pointer rounded-md sm:rounded-0 sm:shadow-none sm:bg-white  sm:bg-none ">
                            <Link
                                to={"/order/Information"}

                                className={` ${location.pathname === '/order/Information' ? 'text-cyan-400 hover:text-gray-400' : 'text-gray-700 hover:text-cyan-400'} inline-flex w-full items-center text-lg font-medium  `}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2.5"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>


                                My Info
                            </Link>
                            <svg
                                className="w-3 h-3 ml-2 text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m1 9 4-4-4-4"
                                />
                            </svg>


                        </li>

                        <li className="inline-flex items-center col-span-1 p-2  cursor-pointer rounded-md sm:rounded-0 sm:shadow-none sm:bg-white  sm:bg-none ">
                            <Link
                                to={"/order/Location"}
                                className={` ${location.pathname === '/order/Location' ? 'text-cyan-400 hover:text-gray-400' : 'text-gray-700 hover:text-cyan-400'} inline-flex w-full items-center text-lg font-medium  `}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2.5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>


                                My Location
                            </Link>
                            <svg
                                className="w-3 h-3 ml-2 text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m1 9 4-4-4-4"
                                />
                            </svg>


                        </li>

                        <li className="inline-flex items-center col-span-1 p-2  cursor-pointer  rounded-md sm:rounded-0 sm:shadow-none sm:bg-white  sm:bg-none ">
                            <Link
                                to={"/order/Items"}
                                className={` ${location.pathname === '/order/Items' ? 'text-cyan-400 hover:text-gray-400' : 'text-gray-700 hover:text-cyan-400'} inline-flex w-full items-center text-lg font-medium  `}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2.5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                </svg>


                                My Items
                            </Link>
                           
                        </li>

                       
                    </ol>
                </nav>
                <div className="col-span-1 hidden md:flex  items-center justify-between px-3">
                    <div className="relative mx-1  rounded-3xl text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
                        <a
                            href="//api.whatsapp.com/send?phone=+971564683285&amp;text=Hello Sir, I want to avail Legend Movers Services"
                            title="view "
                            className="relative block   p-1 text-black text-green-600 hover:text-green-300  "
                        >
                            <span className="absolute-inset-1.5" />
                            <span className="sr-only">whatsapp</span>

                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6 bi bi-whatsapp" viewBox="0 0 16 16">
                                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                            </svg>                                    </a>

                    </div>
                    <div className="relative mx-1  rounded-3xl p-1 text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
                        <a
                            href="tel:+971567560210"
                            title="view "

                            className="relative block   p-1 text-black   "
                        >
                            <span className="absolute-inset-1.5" />
                            <span className="sr-only">Call</span>

                            <PhoneIcon className="h-6 w-6" aria-hidden="true" />
                        </a>

                    </div>
                    <div className="relative  mx-1  rounded-3xl p-1 text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
                        <a
                            href="mailto:support@legendmovers.co?subject='Contact to Legend Mover through Web Email'&body='I want to Avail your services'"
                            title="Send Email"

                            className="relative block   p-1 text-black   "
                        >
                            <span className="absolute-inset-1.5" />
                            <span className="sr-only">Send Email</span>
                            <EnvelopeIcon className="h-6 w-6" aria-hidden="true" />
                        </a>

                    </div>
                </div>

            </div>

        </div>
    </>)
}