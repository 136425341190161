import { Navigate  } from "react-router-dom";
import { OrderPage } from "./orderPageLayout";
import { Location } from "../orderPages/locations";
import { Information } from "../orderPages/myinfo";
import { Items } from "../orderPages/items";
import { E404 } from "../../404/404";

export const ThemeRouteOrder= [
  {
    path: "/",
    element: <OrderPage />,
    children: [
      { path: "/", element: <Navigate to="Items" /> },
      { path: "Information",  element: <Information /> },      
      { path: "Location",  element: <Location /> },      
      { path: "Items",  element: <Items /> },      
      
      { path: "*",  element: <E404 /> },
    ],
  },
];

