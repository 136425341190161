export const OrderedALLItems = [
    {
        name: 'KITCHEN AREA',
        imgSrc: 'kit.png',
        SubItems: [
            {
                name: 'Kitchen Utensils Carton',
                cbm: 0.18,
                subImgSrc: '',
                Quantity: 0,
            },
            {
                name: 'Washing Machine',
                cbm: 0.40,
                subImgSrc: '',
                Quantity: 0
            },
            
             {
                name: 'Food Processor / Blender / Juicer',
                cbm: 0.03,
                subImgSrc: '',
                Quantity: 0
            },
            
             {
                name: 'Upright Fridge / Freezer (Standard)',
                cbm: 0.90,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Stove / Oven / Cooker',
                cbm: 0.30,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Tumble Dryer',
                cbm: 0.37,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Kitchen Table (Medium)',
                cbm: 0.45,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Coffee Maker / Machine',
                cbm: 0.07,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Microwave',
                cbm: 0.10,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Dishwasher',
                cbm: 0.34,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Mini Fridge',
                cbm: 0.00,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Kitchen Table (Small)',
                cbm: 0.23,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Kitchen Table (Large) ',
                cbm: 0.91,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Cutlery Set Carton',
                cbm: 0.04,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Food Items Carton',
                cbm: 0.09,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Crockery Carton',
                cbm: 0.09,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Glassware Carton',
                cbm: 0.09,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Gas Stove/Hob',
                cbm: 0.06,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Serving Trolley',
                cbm: 0.35,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Kitchen Rack',
                cbm: 0.10,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Wine cooler (Small)',
                cbm: 0.26,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Wine cooler (Medium)',
                cbm: 0.57,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Wine cooler (Large)',
                cbm: 1.04,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Upright Fridge / Freezer (Small)',
                cbm: 0.37,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Small oven',
                cbm: 0.08,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Range (Small)',
                cbm: 0.05,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Range (Medium)',
                cbm: 0.26,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            
             {
                name: 'Range (Large)',
                cbm: 0.47,
                subImgSrc: '',
                Quantity: 0
            },
            
            
             {
                name: 'Oven',
                cbm: 0.23,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Kitchen Sink',
                cbm: 0.43,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Kitchen Island ',
                cbm: 1.58,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'High cabinet 2 doors ',
                cbm: 0.20,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'High cabinet 1 door ',
                cbm: 0.12,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Garbage Can (Small) ',
                cbm: 0.13,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Garbage Can (Large) ',
                cbm: 0.53,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Extractor Hood Kitchen ',
                cbm: 0.09,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: ' Countertop (per m)',
                cbm: 0.02,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Commercial stove / Professional range ',
                cbm: 1.58,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Chest Freezer (Standard) ',
                cbm: 0.57,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Chest Freezer (Small) ',
                cbm: 0.34,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Chest Freezer (Large) ',
                cbm: 0.97,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Base cabinet 2 doors ',
                cbm: 0.54,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Base cabinet 1 door ',
                cbm: 0.32,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Baking tray ',
                cbm: 0.02,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'American Style Fridge / Freezer ',
                cbm: 1.85,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Upright Fridge / Freezer (Large) ',
                cbm: 1.26,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Electronic Atta Chakki ',
                cbm: 0.17,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Hood Chimney ',
                cbm: 0.42,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: ' Water Purifiers/RO unit',
                cbm: 0.09,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Microwave Oven & OTG ',
                cbm: 0.13,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Barbeque Grill Large ',
                cbm: 0.42,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Barbeque Grill Small ',
                cbm: 0.16,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Kitchen Appliance Carton ',
                cbm: 0.09,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Electric Tandoor ',
                cbm: 0.11,
                subImgSrc: '',
                Quantity: 0
            },
            
            {
                name: 'Food Processor ',
                cbm: 0.03,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            
        ]
    },
    {
        name: 'DINNIG AREA',
        imgSrc: 'dinnnerarea.png',
        SubItems: [
            {
                name: 'Dining Table (Large)',
                cbm: 1.70,
                subImgSrc: '',
                Quantity: 0,
            },
            {
                name: 'Cabinet (Small)',
                cbm: 0.27,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Dining Table (Small) ',
                cbm: 1.13,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Dining Table (Medium) ',
                cbm: 1.42,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Mirror (Large) ',
                cbm: 0.03,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Stool ',
                cbm: 0.08,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Dining Chair ',
                cbm: 0.29,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Glass Cabinet (Large) ',
                cbm: 0.92,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: ' File cabinets (per m)',
                cbm: 0.92,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: ' File / Filing cabinet (5 Drawers)',
                cbm: 0.24,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: ' File / Filing cabinet (4 Drawers)',
                cbm: 0.2,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'File / Filing cabinet (3 Drawers) ',
                cbm: 0.17,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: ' File / Filing cabinet (2 Drawers)',
                cbm: 0.13,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Cupboard, tall cabinet ',
                cbm: 0.83,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Cocktail Cabinet ',
                cbm: 0.76,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: ' Cabinet, apothecary',
                cbm: 0.42,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Cabinet (Large) ',
                cbm: 0.54,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: ' Cabinet (Medium)',
                cbm: 0.40,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Painting / Picture (Large) ',
                cbm: 0.02,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: ' Wicker / Cane Chair',
                cbm: 0.29,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: ' Voltaire Chair',
                cbm: 0.62,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Tub Armchair ',
                cbm: 0.43,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Sun Lounger ',
                cbm: 0.76,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Stackable Chair ',
                cbm: 0.13,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Rocking Chair ',
                cbm: 0.44,
                subImgSrc: '',
                Quantity: 0
            },
            
            
            {
                name: 'Papasan Chair ',
                cbm: 0.32,
                subImgSrc: '',
                Quantity: 0
            },
            
             
            {
                name: 'Metal Chair ',
                cbm: 0.29,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Folding Chair ',
                cbm: 0.19,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Deck Chair ',
                cbm: 0.91,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Chair, Porch ',
                cbm: 0.29,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Chair, Occasional ',
                cbm: 0.77,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Chair, Lounge ',
                cbm: 0.45,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Chair, Club ',
                cbm: 0.84,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Bench (Small) ',
                cbm: 0.34,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Bench (Medium) ',
                cbm: 0.67,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Bean Bag ',
                cbm: 0.74,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Bar Stool ',
                cbm: 0.11,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Chair ',
                cbm: 0.29,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Utility Cabinet ',
                cbm: 0.86,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Shoe Cabinet 5 Compartments ',
                cbm: 0.25,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Shoe Cabinet 4 Compartments ',
                cbm: 0.18,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Shoe Cabinet 3 Compartments ',
                cbm: 0.21,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: ' Shoe Cabinet 2 Compartments',
                cbm: 0.20,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Glass Cabinet (Small) ',
                cbm: 0.25,
                subImgSrc: '',
                Quantity: 0
            },
             
            {
                name: 'Glass Cabinet (Middle) ',
                cbm: 0.53,
                subImgSrc: '',
                Quantity: 0
            },
             
        
            
        ]
    },
    {
        name: 'LIVING AREA',
        imgSrc: 'livingarea.png',
        SubItems: [            
            {
                name: 'Curtains and Rods',
                cbm: 0.01,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Coffee table (Small) ',
                cbm: 0.24,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Bedside Lamp ',
                cbm: 0.04,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'L Shape 4 Seater Sofa ',
                cbm: 2.11,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Entertainment/TV Unit',
                cbm: 0.95,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Carpet (Small) ',
                cbm: 0.03,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Flat Screen TV (Medium) ',
                cbm: 0.18,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Plant (Small)',
                cbm: 0.02,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Painting / Picture (Small)',
                cbm: 0.01,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Chest of Drawers (Small) ',
                cbm: 0.34,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Flat Screen TV + Stand ',
                cbm: 0.63,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Console / Hall Table ',
                cbm: 0.21,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Computer Desk',
                cbm: 1.00,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Coffee Table (Medium) ',
                cbm: 0.50,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' 1 Seater Sofa',
                cbm: 0.66,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' L Shape 3 Seater Sofa',
                cbm: 1.69,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Chair, Recliner ',
                cbm: 0.45,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Sofa (3 seater) ',
                cbm: 1.46,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Sofa (2 seater) ',
                cbm: 1.06,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Speakers (Medium)',
                cbm: 0.02,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Desk (Standard)',
                cbm: 1.14,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Sculpture/Statue (Small) ',
                cbm: 0.58,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Sculpture /Statue (Large)',
                cbm: 0.40,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Planter Box',
                cbm: 0.05,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Plant Stand',
                cbm: 0.10,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Plant (Medium) ',
                cbm: 0.17,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Plant (Large) ',
                cbm: 0.83,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Corner Cabinet / Unit ',
                cbm: 0.47,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Picture (Small)',
                cbm: 0.00,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Picture (Large) ',
                cbm: 0.01,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Mirror (Small) ',
                cbm: 0.01,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Flowerpot (Small) ',
                cbm: 0.07,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Flowerpot (Medium)',
                cbm: 0.36,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' TV cathode-ray tube screen',
                cbm: 0.34,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Flowerpot (Large)',
                cbm: 1.40,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Cheval Mirror',
                cbm: 0.18,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Carpet (Standard)',
                cbm: 0.05,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Blinds / Shades / Curtains (per window) ',
                cbm: 0.02,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Carpet (Large) ',
                cbm: 0.13,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Wall Unit (Small)',
                cbm: 0.97,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Wall Unit (Medium) ',
                cbm: 1.74,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Wall Unit (Large)',
                cbm: 2.08,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Wall Shelf ',
                cbm: 0.06,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Shelf Single',
                cbm: 0.02,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Shelf Heavy Duty',
                cbm: 0.92,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Shelf (Small) ',
                cbm: 0.30,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Shelf (Medium)',
                cbm: 0.92,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Shelf (Large)',
                cbm: 1.14,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Expedit/Kallax Shelving Unit 5x5 Shelf',
                cbm: 1.64,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Expedit/Kallax Shelving Unit 4x4 Shelf',
                cbm: 0.88,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Expedit/Kallax Shelving Unit 2x4 Shelf ',
                cbm: 0.46,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Expedit/Kallax Shelving Unit 1x4 Shelf ',
                cbm: 0.25,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Expedit/Kallax Shelving Unit 1x2 Shelf',
                cbm: 0.27,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Bookshelf / Bookcase (Small) ',
                cbm: 0.54,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Bookcase (per section) ',
                cbm: 0.55,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Billy Height extension unit',
                cbm: 0.08,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Billy Bookcase (half width)',
                cbm: 0.26,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Billy Bookcase (half height) ',
                cbm: 0.25,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Billy Bookcase',
                cbm: 0.52,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Bookshelf / Bookcase (Medium)',
                cbm: 0.84,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Bookshelf / Bookcase (Large) ',
                cbm: 1.66,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Bar Cabinet Large',
                cbm: 0.68,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Bar Trolley ',
                cbm: 0.22,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Bar Chair',
                cbm: 0.24,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Wine Rack ',
                cbm: 0.08,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Bar Unit ',
                cbm: 0.84,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Bar Cabinet',
                cbm: 0.51,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Living wall (Small) ',
                cbm: 1.26,
                subImgSrc: '',
                Quantity: 0,
            },
            
              
            {
                name: ' Living wall (Large)',
                cbm: 2.77,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Flat Screen TV (Small)',
                cbm: 0.15,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Flat Screen TV (Large)',
                cbm: 0.25,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Beamer Screen ',
                cbm: 0.12,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'TV Board ',
                cbm: 0.23,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Vanity Table',
                cbm: 0.88,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Table, round (2m Diameter)',
                cbm: 3.36,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Table, round (1m Diameter) ',
                cbm: 0.84,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Stand ',
                cbm: 0.13,
                subImgSrc: '',
                Quantity: 0,
            },
              
            {
                name: ' Sofa Table',
                cbm: 0.30,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Side Table',
                cbm: 0.08,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Serving Cart',
                cbm: 1.05,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Pedestal table',
                cbm: 0.16,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Nest of Tables (3) ',
                cbm: 1.36,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Nest of tables',
                cbm: 0.13,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Marble table / Marble item',
                cbm: 1.58,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: ' Living room table',
                cbm: 0.34,
                subImgSrc: '',
                Quantity: 0,
            },
            
            {
                name: 'Folding table ',
                cbm: 0.16,
                subImgSrc: '',
                Quantity: 0,
            },
             
           
            
        ]
    },
]