import { Outlet } from "react-router-dom";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";


export const FulllayoutMainWeb = () => {
    return (
        <main>
            <div style={{ position: "fixed", top: 0, zIndex: "50000000", width: "100vw", boxShadow: ` 5px 5px 10px rgba(0, 0, 0, 0.2)` }} >
                <Header />
            </div>
            <div className="pt-18" >
                <Outlet />
            </div>
            <div>
                <Footer />
            </div>
        </main >
    );
};

