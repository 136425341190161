import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RoutingWeb } from "./components/Main Website/WebRouting/routingcall";
import { RoutingOrder } from "./components/Main Website/OrderPage/orderRouting/routingcall";
import axios from "axios";
import { getReviews } from "./components/Store/authSlice";
import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { AuthAdminRouting } from "./components/Admin Panel/RoutingAdminAuth/Aroutingcall";
// import { RoutingAdminPanel } from "./components/Admin Panel/RoutesAdmin/Admin Dashboard";
function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    async function getReviewsData() {

      try {
        const response = await axios.get(`https://confused-crow-windbreaker.cyclic.app/api/get_reviews`);
        if (response) {

          if (response.status === 200) {
            dispatch(getReviews(response.data.Allreviews))
          }
        }
      } catch (error) {

        console.log(error);
      }
    }
    getReviewsData()




    window.scrollTo({ top: 10, behavior: 'smooth' });
  }, [])


  return (<>
    <Router >
      <Routes>
        <Route path="/*" element={<RoutingWeb />} />
        <Route path="/order/*" element={<RoutingOrder />} />


      </Routes>
    </Router>
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  </>

  );
}

export default App;


