import { Swiper, SwiperSlide } from "swiper/react";
import style from "./slider.module.css"
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import { Pagination, Autoplay, Navigation } from "swiper/modules";
import { useRef } from "react";
import { selectReviews } from "../../Store/authSlice";
import { useSelector } from "react-redux";





export function ReviewsSwipper() {
    const swiperRef = useRef(null);
    const storeReviews = useSelector(selectReviews)

    function renderStars(averageRating) {
        const fullStars = Math.floor(averageRating);
        const halfStar = averageRating - fullStars >= 0.5;
        const stars = [];

        // Add full stars
        for (let i = 0; i < fullStars; i++) {
            stars.push(
                <svg key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#f4dc03" width={"30px"} height={"30px"}>
                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clipRule="evenodd" />
                </svg>
            );
        }

        if (halfStar) {
            stars.push(
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#f4dc03" className="bi bi-star-half" viewBox="0 0 16 16">
                    <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" />
                </svg>


            );
        }

        while (stars.length < 5) {
            stars.push(
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#f4dc03" width={"30px"} height={"30px"}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>
            );
        }

        return stars;
    }

    return (
        <> {storeReviews && <>

            <div    className={`  carosoal  `}>
                <Swiper
                    ref={swiperRef}
                    modules={[Autoplay, Pagination, Navigation]}
                    // navigation
                    // pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}

                    spaceBetween={20}
                    slidesPerView={2}
                    className="mySwiper "  >
                    {
                        storeReviews.map((eachitem, index) => (
                            <SwiperSlide
                                key={index} className={` p-3 ${style.InnerSlide}`}>
                                <div  style={{ boxShadow: ` 5px 5px 10px rgba(0, 0, 0, 0.2)` }} className=" p-2  h-64 bg-white rounded-lg">

                                    <div className="flex items-center justify-between gap-2">

                                        {eachitem.name && <p className="text-center my-2 font-bold">
                                            {eachitem.name}
                                        </p>}
                                        {eachitem.reviewPublish && <p className="text-right my-2 italic text-cyan-500">
                                            {eachitem.reviewPublish.substring(0, 10)}
                                        </p>}
                                    </div>

                                    {eachitem.Stars ? <div className="w-2/6 mx-auto flex items-center justify-between my-2"> {renderStars(eachitem.Stars)}</div> : <div>
                                        No rating
                                    </div>}
                                    {eachitem.reviewDescription && <p className="my-3  text-center font-semibold text-lg">
                                        {eachitem.reviewDescription}
                                    </p>}


                                </div>
                            </SwiperSlide>
                        ))
                    }


                </Swiper>
            </div>

        </>}
        </>
    );
}
