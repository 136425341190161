import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"
import { useState } from "react"
import { selectOrderDetail } from "../../../Store/authSlice";
import { useSelector } from "react-redux";


export function Information() {
    const [openDiv, setopenDiv] = useState(true)
    const StoreOrderDetail = useSelector(selectOrderDetail)



    return (<div>

        <div className="w-10/12 mx-auto border-2 rounded-2xl bg-white my-6">

            <div onClick={() => { setopenDiv(!openDiv) }} className="flex items-center justify-between  cursor-pointer bg-gray-100 py-4 px-4   ">
                <div>
                    <p className="font-bold text-lg">
                        My Info
                    </p>
                </div>
                <div>
                    {openDiv ?
                        <ChevronDownIcon className="w-6 h-6" />
                        :
                        <ChevronUpIcon className="w-6 h-6" />}
                </div>
            </div>

            {StoreOrderDetail ? openDiv && <div className="py-4 px-4">

                {StoreOrderDetail.movingItem &&
                    <div className="flex items-center justify-between gap-2 ">
                         <p className="font-bold ">What Your are Moving?
                        </p>
                         <p className="text-gray-400 font-semibold">
                            {StoreOrderDetail.movingItem}
                        </p>
                    </div>}

                {StoreOrderDetail.city &&
                    <div className="flex items-center justify-between gap-2 ">
                         <p className="font-bold ">Origin City
                        </p>
                         <p className="text-gray-400 font-semibold">
                            {StoreOrderDetail.city}
                        </p>
                    </div>}

                {StoreOrderDetail.OptionMovetype &&
                    <div className="flex items-center justify-between gap-2 ">
                         <p className="font-bold ">Move Type - Local Move (Same City , area , building ) or International Move
                        </p>
                         <p className="text-gray-400 font-semibold">
                            {StoreOrderDetail.OptionMovetype}
                        </p>
                    </div>}

                {StoreOrderDetail.pickUpaddress1 &&
                    <div className="flex items-center justify-between gap-2 ">
                         <p className="font-bold ">Pickup Address - Building/Area Name
                        </p>
                         <p className="text-gray-400 font-semibold">
                            {StoreOrderDetail.pickUpaddress1}
                        </p>
                    </div>}

                {StoreOrderDetail.deliveryAddress1 &&
                    <div className="flex items-center justify-between gap-2 my-1">
                        <p className="font-bold ">
                            Delivery Address - Building/Area Name
                        </p>
                        <p className="text-gray-400 font-semibold">
                            {StoreOrderDetail.deliveryAddress1}
                        </p>
                    </div>}

                {StoreOrderDetail.firstName &&
                    <div className="flex items-center justify-between gap-2 ">
                         <p className="font-bold ">Name
                        </p>
                         <p className="text-gray-400 font-semibold">
                            {StoreOrderDetail.firstName + ' ' + StoreOrderDetail.lastName}
                        </p>
                    </div>}

                {StoreOrderDetail.countrycode &&
                    <div className="flex items-center justify-between gap-2 ">
                         <p className="font-bold ">Contact
                        </p>
                         <p className="text-gray-400 font-semibold">
                            {StoreOrderDetail.countrycode + ' ' + StoreOrderDetail.contact2}
                        </p>
                    </div>}

                {StoreOrderDetail.Useremail &&
                    <div className="flex items-center justify-between gap-2 ">
                         <p className="font-bold ">Email
                        </p>
                         <p className="text-gray-400 font-semibold">
                            {StoreOrderDetail.Useremail}
                        </p>
                    </div>}

                {StoreOrderDetail.moveTime &&
                    <div className="flex items-center justify-between gap-2 ">
                         <p className="font-bold ">Move Time
                        </p>
                         <p className="text-gray-400 font-semibold">
                            {StoreOrderDetail.moveTime}
                        </p>
                    </div>}

                {StoreOrderDetail.moveDate &&
                    <div className="flex items-center justify-between gap-2 ">
                         <p className="font-bold ">Move Date
                        </p>
                         <p className="text-gray-400 font-semibold">
                            {StoreOrderDetail.moveDate}
                        </p>
                    </div>}

            </div> : <p className="flex items-center justify-center text-center">
                No data
            </p>}
        </div>
    </div>)
}