import { ChevronDownIcon, ChevronUpIcon, MapPinIcon } from "@heroicons/react/24/outline"
import { useState } from "react"
import { selectOrderDetail, addData } from "../../../Store/authSlice";
import { useSelector, useDispatch } from "react-redux";

export function Location() {
    const [openDiv, setopenDiv] = useState(true )
    const StoreOrderDetail = useSelector(selectOrderDetail)
    const dispatch = useDispatch()
    const [objectData, setObjectData] = useState(StoreOrderDetail);
    const [pickupAddressformbutton, setpickupAddressformbutton] = useState(false);
    const [deliveryAddressformbutton, setdeliveryAddressformbutton] = useState(false);
    
    
    
    
    
    const today = new Date().toISOString().split('T')[0];

    return (<div>

        <div className="w-10/12 mx-auto border-2 rounded-2xl bg-white my-6">

            <div onClick={() => { setopenDiv(!openDiv) }} className="flex items-center justify-between  cursor-pointer bg-gray-100 py-4 px-4   ">
                <div>
                    <p className="font-bold text-lg">
                        My Location
                    </p>
                </div>
                <div>
                    {openDiv ?
                        <ChevronDownIcon className="w-6 h-6" />
                        :
                        <ChevronUpIcon className="w-6 h-6" />}
                </div>
            </div>

            {openDiv && <div className="py-4 px-4 ">

                <div className="grid grid-cols-2 gap-2">

                    <div className="col-span-2 md:col-span-1 ">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            console.log(objectData);

                            dispatch(addData({
                                ...StoreOrderDetail,
                                ...objectData
                            }));


                        }} style={{ boxShadow: ` 3px 3px 7px rgba(0, 0, 0, 0.2)` }} className={` relative  bg-gray-100 rounded-md   mx-auto px-2  py-4 `}>
                            <h2 className="font-bold text-lg text-center ">PickUp Address</h2>

                            <div>
                                <h2 className="font-semibold text-xs my-1 ">Pickup Address - Building/Area Name</h2>
                                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">
                                    <MapPinIcon className="h-4 w-4" aria-hidden="true" />

                                    <input
                                        required

                                        type="text" value={objectData.pickUpaddress1} onChange={(e) => {
                                            setObjectData((prevObjectData) => ({
                                                ...prevObjectData,
                                                pickUpaddress1: e.target.value,
                                            }))
                                            setpickupAddressformbutton(true)
                                        }} placeholder="Enter Complete PickUp Address " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                    />
                                </div>
                            </div>



                            <div className="grid grid-cols-3 place-items-center  gap-2">
                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">Area</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">


                                        <input
                                            required
                                            type="text" value={objectData.pickUpArea} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    pickUpArea: e.target.value,
                                                }))

                                                setpickupAddressformbutton(true)

                                            }} placeholder="Enter Area " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">  City</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                        </svg>


                                        <input
                                            required
                                            min={today}
                                            type="text" value={objectData.PickUpCity} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    PickUpCity: e.target.value,
                                                }))

                                                setpickupAddressformbutton(true)

                                            }} placeholder="Enter City" name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">  state</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                        </svg>


                                        <input
                                            required
                                            min={today}
                                            type="text" value={objectData.PickUpState} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    PickUpState: e.target.value,
                                                }))
                                                setpickupAddressformbutton(true)


                                            }} placeholder="Enter State" name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-5   gap-2">
                                <div className="col-span-2">
                                    <h2 className="font-semibold text-xs my-1 ">Building Name</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                        </svg>


                                        <input
                                            required
                                            type="text" value={objectData.PickUpBuildingdName} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    PickUpBuildingdName: e.target.value,
                                                }))
                                                setpickupAddressformbutton(true)


                                            }} placeholder="Enter Building Name " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>


                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">  Unit Number</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                        </svg>


                                        <input
                                            required
                                            min={today}
                                            type="Number" value={objectData.PickUpBuildingUnitNumber} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    PickUpBuildingUnitNumber: e.target.value,
                                                }))
                                                setpickupAddressformbutton(true)


                                            }} placeholder="Enter City" name="" id="" className="bg-none py-1 text-sm  w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">  Floor Number</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                        </svg>


                                        <input
                                            required
                                            min={today}
                                            type="number" value={objectData.PickUpBuildingFloorNumber} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    PickUpBuildingFloorNumber: e.target.value,
                                                }))
                                                console.log(objectData);
                                                setpickupAddressformbutton(true)

                                            }} placeholder="Enter Floor Number" name="" id="" className="bg-none py-1 text-sm  w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">  Service Lift </h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <div className="flex items-center justify-between gap-1 py-1" >
                                            <p className="text-xs font-bold">
                                                Yes
                                            </p>
                                            <input
                                                required
                                                min={today}
                                                type="radio" value={objectData.PickUpBuildingServiceLift} onChange={(e) => {
                                                    setObjectData((prevObjectData) => ({
                                                        ...prevObjectData,
                                                        PickUpBuildingServiceLift: true,
                                                    }))
                                                    setpickupAddressformbutton(true)

                                                }} placeholder="Enter City" name="liftRadio" id="liftRadio2" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                            />
                                        </div>
                                        <div className="flex items-center justify-between gap-1" >
                                            <p className="text-xs font-bold">
                                                No
                                            </p>
                                            <input
                                                required
                                                min={today}
                                                type="radio" value={objectData.PickUpBuildingServiceLift} onChange={(e) => {
                                                    setObjectData((prevObjectData) => ({
                                                        ...prevObjectData,
                                                        PickUpBuildingServiceLift: false,
                                                    }))
                                                    setpickupAddressformbutton(true)

                                                }} placeholder="Enter City" name="liftRadio" id="liftRadio" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                            />
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div>
                                <h2 className="font-semibold text-xs my-1 ">Distance from Truck Parking to Delivery Point in Meters?</h2>

                                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">
                                    <MapPinIcon className="h-4 w-4" aria-hidden="true" />

                                    <input
                                        required

                                        type="text" value={objectData.DistancePickupPoint} onChange={(e) => {
                                            setObjectData((prevObjectData) => ({
                                                ...prevObjectData,
                                                DistancePickupPoint: e.target.value,
                                            }))

                                            setpickupAddressformbutton(true)


                                        }} placeholder="Distance from Truck Parking to Pickup Point in Meters? " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                    />
                                </div>
                            </div>


                            {pickupAddressformbutton && <button type="submit" className="w-full my-2 p-2 text-center shadow hover:shadow-none border-0 rounded-lg bg-cyan-300 ">
                                Save
                            </button>}


                        </form>
                    </div>

                    <div className="col-span-2 md:col-span-1 ">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            console.log(objectData);
                            dispatch(addData({
                                ...StoreOrderDetail,
                                ...objectData
                            }));

                        }} style={{ boxShadow: ` 3px 3px 7px rgba(0, 0, 0, 0.2)` }} className={` relative  bg-gray-100 rounded-md    mx-auto px-2  py-4 `}>
                            <h2 className="font-bold text-lg text-center ">Delivery Address</h2>

                            <div>
                                <h2 className="font-semibold text-xs my-1 ">Delivery Address - Building/Area Name</h2>
                                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">
                                    <MapPinIcon className="h-4 w-4" aria-hidden="true" />

                                    <input
                                        required

                                        type="text" value={objectData.deliveryAddress1} onChange={(e) => {
                                            setObjectData((prevObjectData) => ({
                                                ...prevObjectData,
                                                deliveryAddress1: e.target.value,
                                            }))
                                            setdeliveryAddressformbutton(true)
                                        }} placeholder="Enter Complete PickUp Address " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                    />
                                </div>
                            </div>



                            {/*4 clender buildind */}
                            <div className="grid grid-cols-3 place-items-center  gap-2">
                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">Area</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">


                                        <input
                                            required
                                            type="text" value={objectData.DeliveryArea} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    DeliveryArea: e.target.value,
                                                }))
                                                setdeliveryAddressformbutton(true)


                                            }} placeholder="Enter Area " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">  City</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                        </svg>


                                        <input
                                            required
                                            min={today}
                                            type="text" value={objectData.DeliveryCity} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    DeliveryCity: e.target.value,
                                                }))
                                                setdeliveryAddressformbutton(true)


                                            }} placeholder="Enter City" name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">  state</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                        </svg>


                                        <input
                                            required
                                            min={today}
                                            type="text" value={objectData.DeliveryState} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    DeliveryState: e.target.value,
                                                }))
                                                setdeliveryAddressformbutton(true)


                                            }} placeholder="Enter State" name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-5   gap-2">
                                <div className="col-span-2">
                                    <h2 className="font-semibold text-xs my-1 ">Building Name</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                        </svg>


                                        <input
                                            required
                                            type="text" value={objectData.DeliveryBuildingdName} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    DeliveryBuildingdName: e.target.value,
                                                }))

                                                setdeliveryAddressformbutton(true)

                                            }} placeholder="Enter Building Name " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>


                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">  Unit Number</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                        </svg>


                                        <input
                                            required
                                            min={today}
                                            type="Number" value={objectData.DeliveryBuildingUnitNumber} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    DeliveryBuildingUnitNumber: e.target.value,
                                                }))
                                                setdeliveryAddressformbutton(true)


                                            }} placeholder="Enter City" name="" id="" className="bg-none py-1 text-sm  w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">  Floor Number</h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                        </svg>


                                        <input
                                            required
                                            min={today}
                                            type="number" value={objectData.DeliveryBuildingFloorNumber} onChange={(e) => {
                                                setObjectData((prevObjectData) => ({
                                                    ...prevObjectData,
                                                    DeliveryBuildingFloorNumber: e.target.value,
                                                }))
                                                console.log(objectData);
                                                setdeliveryAddressformbutton(true)

                                            }} placeholder="Enter Floor Number" name="" id="" className="bg-none py-1 text-sm  w-full focus:outline-none focus:ring-none "
                                        />
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <h2 className="font-semibold text-xs my-1 ">  Service Lift </h2>
                                    <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                                        <div className="flex items-center justify-between gap-1 py-1" >
                                            <p className="text-xs font-bold">
                                                Yes
                                            </p>
                                            <input
                                                required
                                                min={today}
                                                type="radio" value={objectData.DeliveryBuildingServiceLift} onChange={(e) => {
                                                    setObjectData((prevObjectData) => ({
                                                        ...prevObjectData,
                                                        DeliveryBuildingServiceLift: true,
                                                    }))
                                                    setdeliveryAddressformbutton(true)

                                                }} placeholder="Enter City" name="liftRadio" id="liftRadio2" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                            />
                                        </div>
                                        <div className="flex items-center justify-between gap-1" >
                                            <p className="text-xs font-bold">
                                                No
                                            </p>
                                            <input
                                                required
                                                min={today}
                                                type="radio" value={objectData.DeliveryBuildingServiceLift} onChange={(e) => {
                                                    setObjectData((prevObjectData) => ({
                                                        ...prevObjectData,
                                                        DeliveryBuildingServiceLift: false,
                                                    }))
                                                    setdeliveryAddressformbutton(true)

                                                }} placeholder="Enter City" name="liftRadio" id="liftRadio" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                            />
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div>
                                <h2 className="font-semibold text-xs my-1 ">Distance from Truck Parking to Delivery Point in Meters?</h2>
                                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">
                                    <MapPinIcon className="h-4 w-4" aria-hidden="true" />

                                    <input
                                        required

                                        type="text" value={objectData.DistanceDeliveryPoint} onChange={(e) => {
                                            setObjectData((prevObjectData) => ({
                                                ...prevObjectData,
                                                DistanceDeliveryPoint: e.target.value,
                                            }))
                                            setdeliveryAddressformbutton(true)



                                        }} placeholder="Distance from Truck Parking to Pickup Point in Meters? " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                                    />
                                </div>
                            </div>


                            {deliveryAddressformbutton && <button type="submit" className="w-full my-2 p-2 text-center shadow hover:shadow-none border-0 rounded-lg bg-cyan-300 ">
                                Next
                            </button>}


                        </form>
                    </div>
                </div>

            </div>}
        </div>




    </div>)
}