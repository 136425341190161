import { Outlet } from "react-router-dom"
import { Breadcrumb } from "../../breadcum/breadcum"

export function OrderPage() {
    return (<div>
        <Breadcrumb currentComponent={'My Locations'} />
        <div className="pt-36 md:pt-20">
            <Outlet />
        </div>
    </div>)
}