
export function ContactUs() {

    return (<>
        {/* Container for demo purpose */}
        <div className="container my-20 px-6 mx-auto">
            {/* Section: Design Block */}
            <section className="mb-4 text-gray-800 ">
                <div className="flex flex-wrap  ">
                    <div className="grow-0 shrink-0 flex items-center justify-center    basis-auto mb-6 md:mb-0 w-full md:w-6/12 divide-x px-3 lg:px-6     ">
                        <div className=" ">


                            <h2 className="text-3xl font-bold mb-6">Get in touch</h2>
                            <p className="text-gray-900 mb-6">
                                Fill in the form to start a conversation.
                            </p>

                            <p className="flex items-center mb-4">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                                </svg>
                                <span>+971567560210</span>
                            </p>
                            <p className="flex items-center mb-4">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                </svg>
                                <span>support@legendmovers.co</span>
                            </p>
                        </div>
                    </div>
                    <div className="grow-0 shrink-0 basis-auto mb-12 md:mb-0 w-full   md:w-6/12 px-3 lg:px-6">
                        <img src="/contactus.jpg" alt="img" className="w-82 h-82" />
                    </div>
                </div>
            </section>
            {/* Section: Design Block */}
        </div>
        {/* Container for demo purpose */}



    </>)
}

