import React from "react";

export function E404(){
    return(<div>
        {/* <h2>uamir</h2> */}
    <div style={{width:"100%" , height:"82vh", paddingTop:"1rem"  , display:"flex", justifyContent:"center"}}>

    <img  width={"fit-content"} height={"100%"}  src="/404.jpg" alt="img" />
    </div>
    </div>)
}