import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"
import { useState, useEffect, useRef } from "react"
import { OrderedALLItems } from "./ALLitems"
import { selectOrderDetail, addData } from "../../../Store/authSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Loader } from "../../Loader/loader";
import { toast } from "react-toastify";


export function Items() {
    const [openDiv, setopenDiv] = useState(true)
    const [loading, setloading] = useState(false)
    const StoreOrderDetail = useSelector(selectOrderDetail)
    const dispatch = useDispatch()


    const [Cartitems, setCartItems] = useState([])
    const [OrderedItemsState, setOrderedItemsState] = useState(OrderedALLItems)
    const intialData = {
        name: '',
        cbm: 'undefined',
        Quantity: 0,
        type: 'User Input'
    }
    const [manaualAddItem, setmanaualAddItem] = useState(intialData)

    const [openBox, setopenBox] = useState(
        OrderedALLItems.map(() => false)
    );
    const toggleBoxOpen = (index) => {
        const updatedStates = [...openBox];
        updatedStates[index] = !updatedStates[index];
        setopenBox(updatedStates);
    };
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSubitems, setfilteredSubitems] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [orderbutton, setorderbutton] = useState(false);

    const containerRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // console.log(OrderedItemsState);

    function calculatesTotalCBM() {
        if (Cartitems) {
            return Cartitems.reduce((accumulator, item) => {
                if (item.cbm) {
                    return accumulator + (item.cbm * item.Quantity);
                }
                return accumulator;
            }, 0);
        } else {
            return 0;
        }

    }

    async function saveDatatoServer() {
        console.log("save to server");
        function isObjectEmpty(obj) {
            for (const key in obj) {

                if (Array.isArray(obj[key])) {
                    if (obj[key].length === 0) {
                        return false;
                    }
                } else if (obj[key] === null || obj[key] === undefined) {
                    return false;
                } else if (typeof obj[key] === 'string' && obj[key].trim() === '') {
                    return false;
                }
            }
            return true;
        }
        if (isObjectEmpty(StoreOrderDetail)) {
            console.log("All attributes are not empty");
            try {
                setloading(true)
                const response = await axios.post('https://confused-crow-windbreaker.cyclic.app/api/place_order', StoreOrderDetail)
                if (response.status === 200) {
                    setloading(false)
                    toast.success("Order Placed Successfully")
                }
            } catch (error) {
                setloading(false)
                console.log(error);
                toast.error("Unable to Place Order , try again Later")
                if (error.response) {
                    console.log(error.response.message);
                }
            }
        } else {
            console.log("Some attributes are empty");
            toast.error("Please Input Oder Details First ")
        }





    }


    return (<div>

        <div className="w-10/12 mx-auto border-2 rounded-2xl bg-white my-6">

            <div onClick={() => { setopenDiv(!openDiv) }} className="flex items-center justify-between  cursor-pointer bg-gray-100 py-4 px-4   ">
                <div>
                    <p className="font-bold text-lg text-center">
                        My Items
                    </p>
                    <div className="italic">
                        Total Items : <span>{Cartitems.length}</span> Total CBM: <span>{calculatesTotalCBM()}</span>
                    </div>
                </div>
                <div>
                    {openDiv ?
                        <ChevronDownIcon className="w-6 h-6" />
                        :
                        <ChevronUpIcon className="w-6 h-6" />}
                </div>
            </div>

            {openDiv && <div className="px-4 py-2 ">
                <div className="text-center my-2">
                    <h1 className="font-bold py-1 ">
                        Typical Inventory Items Added
                    </h1>
                    <p className="italic">
                        Based on your 5 Bedrooms House, we have created a typical inventory list as a starting point. Please add or remove the items as you wish.Your inventory affects your price!
                    </p>
                </div>

                <form onSubmit={(e) => {
                    e.preventDefault()
                    Cartitems.push(manaualAddItem)
                    setmanaualAddItem(intialData)
                    setorderbutton(false)


                }} className="  my-2 py-1 px-4 ">
                    <h1 className="font-bold  text-center text-lg text-cyan-400 ">
                        Add Item
                    </h1>
                    <div className="gap-2 grid grid-cols-2  ">
                        <div className="col-span-2 md:col-span-1 flex items-center justify-center gap-2 my-2">
                            <h3 className="font-semibold italic">Item Name: </h3>
                            <input  required value={manaualAddItem.name} onChange={(e) => {
                                setmanaualAddItem((predata) => ({ ...predata, name: e.target.value }))
                            }} placeholder="Add item..." type="text" name="" id="" className="focus:outline-none focus:ring-0 border-2 shadow rounded py-1 px-2  " />
                        </div>
                        <div className=" col-span-2 md:col-span-1 flex items-center justify-center gap-2 my-2">
                            <h3 className="font-semibold italic">Item Quantity: </h3>
                            <input  required  value={manaualAddItem.Quantity} onChange={(e) => {
                                setmanaualAddItem((pre) => ({ ...pre, Quantity: e.target.value }))
                            }} placeholder="Quantity.." type="Number" name="" id="" className="focus:outline-none focus:ring-0 border-2 shadow rounded py-1 px-2  " />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <button className="text-center my-4 w-full  bg-cyan-400 px-4 py-2 rounded-md text-white" type="submit">
                            Add item
                        </button>
                    </div>
                </form>


                <div className="grid grid-cols-2  mt-4 gap-2 px-2">
                    <div className="col-span-2 sm:col-span-1">

                        {OrderedItemsState.map((items, index) => {
                            return <div ref={containerRef} key={index} className="rounded-xl  border-2  my-2  bg-white px-2 py-2" >
                                <div className="  flex items-center justify-between  hover:text-cyan-400 ">
                                    <div className="flex items-center justify-start gap-2 ">
                                        <img src={"/" + items.imgSrc} alt="img" className="w-6 h-6" />
                                        <h2>{items.name}</h2>
                                    </div>
                                    <div onClick={() => { toggleBoxOpen(index) }} className="flex cursor-pointer  items-center justify-start gap-2 ">
                                        {/* <span className="rounded-xl text-white bg-cyan-400 w-2 h-2 p-3 flex items-center justify-center ">2</span> */}
                                        {openBox[index] ?
                                            <ChevronDownIcon className="w-6 h-6 text-cyan-400" />
                                            :
                                            <ChevronUpIcon className="w-6 h-6 text-cyan-400" />}
                                    </div>
                                </div>

                                {openBox[index] &&
                                    <div className="mt-2 relative">
                                        <div className=" rounded-xl p-1 my-4  bg-white border-2 flex items-center justify-between shadow-md ">
                                            <input value={searchQuery} onChange={(e) => {
                                                setSearchQuery(e.target.value)

                                                const itemsnew = items.SubItems.filter((subitem) => {
                                                    return subitem.name.toLowerCase().includes(searchQuery.toLowerCase());
                                                });
                                                setfilteredSubitems(itemsnew)
                                                setIsDropdownOpen(true);
                                            }} placeholder="Search..." type="text" className="w-full focus:outline-none focus:ring-0 " />

                                            <svg type="submit" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-cyan-400" >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </div>
                                        {isDropdownOpen && (
                                            <div className="w-full absolute top-10 left-0 bg-gray-200 rounded-lg shadow-md py-1 px-2 ">
                                                {filteredSubitems.map((subitem, index2) => (
                                                    <p
                                                        key={index2}
                                                        className="cursor-pointer w-full text-gray-500 text-lg py-1 px-2 hover:bg-blue-600 hover:text-white"
                                                    >
                                                        {subitem.name}
                                                    </p>
                                                ))}
                                            </div>
                                        )}
                                        {items.SubItems.map((subitems, index2) => {
                                            const itemInCart = Cartitems.find((item) => item.name === subitems.name);

                                            return <div key={index2} className="flex border-b border-gray-300 pb-2 items-center justify-between gap-2 my-3">

                                                <div className="flex items-center justify-start gap-2 ">
                                                    {/* <img src={"/" + subitems.subImgSrc} alt="img" className="w-6 h-6" /> */}
                                                    <h2>{subitems.name}</h2>
                                                </div>
                                                <div className="flex items-center justify-between gap-2 ">
                                                    {/* minus svg */}
                                                    <svg

                                                        onClick={() => {

                                                            setorderbutton(false)
                                                            if (itemInCart) {
                                                                if (itemInCart.Quantity > 0) {
                                                                    itemInCart.Quantity -= 1;
                                                                }

                                                                if (itemInCart.Quantity === 0) {
                                                                    // Remove the item from the cart if the quantity becomes zero
                                                                    setCartItems(Cartitems.filter((item) => item.name !== subitems.name));
                                                                }
                                                            }
                                                            if (subitems.Quantity > 0) {
                                                                subitems.Quantity -= 1;


                                                                const updatedOrderedItems = [...OrderedItemsState];
                                                                const categoryIndex = updatedOrderedItems.findIndex((category) => category.name === items.name);
                                                                if (categoryIndex !== -1) {
                                                                    const subitemIndex = updatedOrderedItems[categoryIndex].SubItems.findIndex((item) => item.name === subitems.name);
                                                                    if (subitemIndex !== -1) {
                                                                        updatedOrderedItems[categoryIndex].SubItems[subitemIndex].Quantity = subitems.Quantity;
                                                                    }
                                                                }

                                                                setOrderedItemsState(updatedOrderedItems);
                                                            }
                                                        }}


                                                        xmlns="http://www.w3.org/2000/svg" fill="#00cfff" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-cyan-400 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
                                                    </svg>

                                                    <span>{subitems.Quantity}</span>
                                                    {/* plus svg */}
                                                    <svg
                                                        onClick={() => {
                                                            setorderbutton(false)

                                                            subitems.Quantity += 1
                                                            if (itemInCart) {
                                                                itemInCart.Quantity += 1;
                                                            } else {
                                                                setCartItems([...Cartitems, { ...subitems, Quantity: 1 }]);
                                                            }
                                                            const updatedOrderedItems = [...OrderedItemsState];
                                                            // Find the category and subitem in the copied array and update the quantity.
                                                            const categoryIndex = updatedOrderedItems.findIndex((category) => category.name === items.name);
                                                            if (categoryIndex !== -1) {
                                                                const subitemIndex = updatedOrderedItems[categoryIndex].SubItems.findIndex((item) => item.name === subitems.name);
                                                                if (subitemIndex !== -1) {
                                                                    updatedOrderedItems[categoryIndex].SubItems[subitemIndex].Quantity = subitems.Quantity;
                                                                }
                                                            }
                                                            // Update the state with the modified array.
                                                            setOrderedItemsState(updatedOrderedItems);
                                                        }}
                                                        xmlns="http://www.w3.org/2000/svg" fill="#00cfff" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-cyan-400 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                                                    </svg>

                                                </div>
                                            </div>

                                        })}
                                    </div>
                                }
                            </div>
                        })}
                    </div>

                    <div className="col-span-2 sm:col-span-1 rounded-lg bg-gray-100 ">
                        <div className=" p-3 border-b border-gray-400 flex items-center justify-between gap-2">
                            <div> <span className="font-semibold "> Total Items: </span> <span>{Cartitems.length}</span></div>
                            <div> <span className="font-semibold">  Total CBM: </span> <span>{calculatesTotalCBM()}</span></div>
                        </div>

                        <div className="p-3">
                            <div className="flex items-center font-bold justify-between gap-2 ">

                                <div className="flex items-center justify-center gap-2 ">
                                    <h2>items Name</h2>
                                </div>
                                <div className="flex items-center justify-center gap-2 ">
                                    <span>Item CBM</span>
                                </div>
                                <div className="flex items-center justify-center gap-2 ">
                                    <span>Items Quantity</span>
                                </div>

                            </div>
                            {Cartitems.map((items, index) => {
                                return <div key={index} className=" grid grid-cols-3  border-b border-gray-300 pb-2   gap-2 my-3">

                                    <div className="text-left ">
                                        {/* <img src="/kitchen.png" alt="img" className="w-6 h-6" /> */}
                                        <h2>{items.name}</h2>
                                    </div>
                                    <div className="flex items-center justify-center gap-2 ">
                                        <span>{items.cbm}</span>
                                    </div>
                                    <div className="flex items-center justify-center gap-2 ">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="#00cfff" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-cyan-400 cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
                                    </svg> */}

                                        <span>{items.Quantity}</span>

                                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="#00cfff" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-cyan-400 cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                                    </svg> */}

                                    </div>

                                </div>
                            })}

                        </div>
                        {orderbutton && <div className=" w-full   flex items-center justify-end gap-2 px-2 py-2">
                            <button onClick={saveDatatoServer} className="bg-green-500  w-full text-white px-4 py-2 rounded-md ">
                                Place Order
                            </button>
                        </div>}
                    </div>

                </div>

                <button onClick={() => {
                  


                    dispatch(addData({
                        ...StoreOrderDetail,
                        cartItems: [...Cartitems],
                    }));

                    setorderbutton(true)

                }} className="w-full py-2 bg-cyan-400 rounded-md my-2 text-white shadow hover:shadow-none ">
                    Save Items
                </button>
            </div>}


        </div>


        <Loader loading={loading} />
    </div >)
}