import { Link } from "react-router-dom"

export function Footer() {
    return (<div>

        {/* footer Links */}
        <div className="bg-gray-100 ">
            <div className="text-left py-10 px-4 w-11/12 mx-auto grid grid-cols-4 gap-4   " >
                <div className="col-span-2 md:col-span-1">
                    <h2 className="font-bold my-2  ">
                        ABOUT US
                    </h2>
                    <div>

                        <Link to={"/Privacy"} className="text-gray-500 font-base my-1 cursor-pointer hover:text-cyan-500">
                            Privacy Policy
                        </Link>
                    </div>
                    <div>

                        <Link to={"/Terms"} className="text-gray-500 font-base my-1  cursor-pointer hover:text-cyan-500 ">
                            Terms &amp; Conditions
                        </Link>
                    </div>
                   
                </div>

                <div className="col-span-2 md:col-span-1">
                    <h2 className="font-bold my-2 ">
                        PARTNERSHIPS
                    </h2>
                    <p className="text-gray-500 font-base my-1 ">
                        For Service Providers
                    </p>
                    <p className="text-gray-500 font-base my-1 ">
                        Corporate Relocation
                    </p>
                    <p className="text-gray-500 font-base my-1 ">
                        For Real Estate Agents            </p>
                </div>

                <div className="col-span-2 md:col-span-1">
                    <h2 className="font-bold my-2 ">
                        BLOG
                    </h2>
                    <p className="text-gray-500 font-base my-1 ">
                        Moving Tips
                    </p>
                    <p className="text-gray-500 font-base my-1 ">
                        Moving Costs
                    </p>
                    <p className="text-gray-500 font-base my-1 ">
                        Before Moving
                    </p>
                </div>

                <div className="col-span-2 md:col-span-1">
                    <h2 className="font-bold my-2 ">
                        SUPPORT
                    </h2>
                    <Link to={"/ContactUs"} className="text-gray-500 font-base my-1 cursor-pointer hover:text-cyan-500 ">
                        Contact Us
                    </Link>
                    <p onClick={() => {
                        window.scrollTo({ top: 2200, behavior: "smooth" })
                    }} className="text-gray-500 font-base my-1  cursor-pointer hover:text-cyan-500">
                        FAQ
                    </p>
                    <p className="text-gray-500 font-base my-1 ">
                        Sat-Thu, 9AM-6PM
                    </p>
                </div>

            </div>
        </div>

        {/* Bule foorter */}
        <div style={{ backgroundColor: "#001943" }}>
            <div className="text-left py-4 px-4 w-11/12 mx-auto flex items-center md:justify-between justify-center flex-wrap gap-4 text-center   " >

                <div className="flex items-center justify-between flex-wrap gap-4 text-center">
                    <img src="/mastervisa.png" alt="img" className="w-14 h-6" />
                    <img src="/facebook.png" alt="img" className="w-6 h-6" />
                    <img src="/tw.png" alt="img" className="w-6 h-6" />
                    <img src="/insta.png" alt="img" className="w-6 h-6" />
                    <img src="/linkind.png" alt="img" className="w-6 h-6" />

                </div>

                <div className="text-white font-base text-sm text-center">
                    <p>
                        Copyright © 2016-2023 Legend Movers. All rights reserved.
                    </p>
                </div>

            </div>
        </div>
    </div>)
}