import React from "react";
import { Disclosure, } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, } from '@heroicons/react/24/outline'
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/solid'
// import style from "./header.module.css"

import { Link } from "react-router-dom";


export function Header() {


    const navigation = [
        { name: 'How it Works?', to: 350 },
        { name: 'Services', to: 1600 },
        { name: 'Questions?', to: 2300 },

    ]


    return (<div >


        <Disclosure as="nav" className="bg-white z-40  ">
            {({ open }) => (
                <>
                    <div className=" w-full sm:px-6 ">
                        <div className="relative flex  items-center justify-between">

                            {/* logo */}
                            <div className="flex flex-1 items-center  sm:items-stretch justify-start">

                                <Link to={'/'} className="flex flex-shrink-0 mx-1 items-center">
                                    <img
                                        title="Facebook"
                                        className="h-20 w-28 sm:h-24 sm:w-36"
                                        src="/LegendMoverslogo.png"
                                        alt="Your Company"
                                    />
                                </Link>
                            </div>

                            {/* tabs contacts*/}
                            <div className="absolute mr-14 sm:mr-0 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:pr-0">

                                <div className="hidden    sm:block">
                                    <div className="flex ">
                                        {navigation.map((item, index) => (
                                            <button
                                                onClick={() => { window.scrollTo({ behavior: "smooth", top: item.to }) }}
                                                key={index}
                                                className={' text-gray-700 cursor-pointer  hover:text-gray-500 px-3 py-2 text-lg font-semibold'}

                                            >
                                                {item.name}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                <div className="relative mx-1  rounded-3xl text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
                                    <a
                                        href="//api.whatsapp.com/send?phone=+971564683285&amp;text=Hello Sir, I want to avail Legend Movers Services"
                                        title="view "
                                        className="relative block   p-1 text-black text-green-600 hover:text-green-300  "
                                    >
                                        <span className="absolute-inset-1.5" />
                                        <span className="sr-only">whatsapp</span>

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6 bi bi-whatsapp" viewBox="0 0 16 16">
                                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                        </svg>                                    </a>

                                </div>
                                <div className="relative mx-1  rounded-3xl p-1 text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
                                    <a
                                        href="tel:+971567560210"
                                        title="view "

                                        className="relative block   p-1 text-black   "
                                    >
                                        <span className="absolute-inset-1.5" />
                                        <span className="sr-only">Call</span>

                                        <PhoneIcon className="h-6 w-6" aria-hidden="true" />
                                    </a>

                                </div>
                                <div className="relative  mx-1  rounded-3xl p-1 text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
                                    <a
                                        href="mailto:support@legendmovers.co?subject='Contact to Legend Mover through Web Email'&body='I want to Avail your services'"
                                        title="Send Email"

                                        className="relative block   p-1 text-black   "
                                    >
                                        <span className="absolute-inset-1.5" />
                                        <span className="sr-only">Send Email</span>
                                        <EnvelopeIcon className="h-6 w-6" aria-hidden="true" />
                                    </a>

                                </div>
                                <div className=" hidden lg:block  mx-1   px-2 border-l-2 border-gray-300   text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
                                    <a
                                        href="mailto:support@legendmovers.co?subject='Contact to Legend Mover through Web Email'&body='I want to Avail your services'"
                                        title="Send Email"

                                        className="relative flex items-center justify-between gap-2 text-lg font-semibold   p-1 text-black cursor-default "
                                    >
                                        <img src="/uaeflag.png" alt="uaeflag" className="h-6 w-6" aria-hidden="true" />
                                        UNITED ARAB EMIRATES
                                    </a>

                                </div>

                            </div>

                            {/* breadcum */}
                            <div className="absolute mr-6 sm:mr-0 inset-y-0 right-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className=" border-2 relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-cyan-300 hover:border-0 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>


                        </div>
                    </div>



                    {/* mobile drop down items */}
                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2 w-full ">
                            <span
                                onClick={() => { window.scrollTo({ behavior: "smooth", top:350 }) }}                            >
                                <div className={
                                    ' hover:bg-blue-400 hover:text-white  cursor-pointer  bg-gray-100 text-gray-500 block rounded-md px-3 py-2 text-base font-medium w-full my-2 flex justify-between items-center '
                                } >
                                    <div>
                                        how it Works?
                                    </div>
                                </div>
                            </span>
                            <span
                                onClick={() => { window.scrollTo({ behavior: "smooth", top: 2600}) }}                            >
                                <div className={
                                    ' hover:bg-blue-400 hover:text-white  cursor-pointer  bg-gray-100 text-gray-500 block rounded-md px-3 py-2 text-base font-medium w-full my-2 flex justify-between items-center '
                                } >
                                    <div>
                                    Services
                                    </div>
                                </div>
                            </span>
                            <span
                                onClick={() => { window.scrollTo({ behavior: "smooth", top: 4200}) }}                            >
                                <div className={
                                    ' hover:bg-blue-400 hover:text-white  cursor-pointer  bg-gray-100 text-gray-500 block rounded-md px-3 py-2 text-base font-medium w-full my-2 flex justify-between items-center '
                                } >
                                    <div>
                                    Questions
                                    </div>
                                </div>
                            </span>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure >

    </div >
    )
}