// import { ReviewsWidget } from "../ReviewsWidget/ReviewsWidget";
import { useRef, useState } from "react";
import style from "./home.module.css"
import { faq } from "./faqandanswers";
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { PhoneIcon, EnvelopeIcon, MapPinIcon } from '@heroicons/react/24/solid'
import { options } from "./options";
import Select from 'react-select'
import { selectOrderDetail, addData } from "../../Store/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReviewsSwipper } from "../Swiper/ReviewsSlider";
import { toast } from "react-toastify";
import axios from "axios";
import { Loader } from "../Loader/loader";

import { selectReviews, getReviews } from "../../Store/authSlice";


export function Home() {
  const storeReviews = useSelector(selectReviews)

  const formRoom = [
    {
      name: ' Studio Apartment', src: 's1.png'
    },
    {
      name: '1 Bedroom House', src: 'a1.png'
    },
    {
      name: '2 Bedroom House', src: 'a2.png'
    },
    {
      name: '3 Bedroom House', src: 'a3.png'
    },
    {
      name: '4 Bedroom House', src: 'a4.png'
    },
    {
      name: '5 Bedroom House', src: 'a5.png'
    },
    {
      name: '5+ Bedroom House', src: 'a6.png'
    },
    {
      name: 'Few house hold Items', src: 'a7.png'
    },
    {
      name: 'Office / Commercial', src: 'a8.png'
    },
  ]

  const uaeCities = [
    {
      name: 'Abu Dhabi'
    },
    {
      name: 'Dubai'
    },
    {
      name: 'Sharjah'
    },
    {
      name: 'Al Ain'
    },
    {
      name: 'Ajman'
    },
    {
      name: 'Ras Al Khaimah'
    },
    {
      name: 'Fujairah'
    },
    {
      name: 'Umm Al-Quwain'
    },
    {
      name: 'Khor Fakkan'
    },
    {
      name: 'Kalba'
    },
    {
      name: 'Dhaid'
    },
    {
      name: 'Jebel Ali'
    },
    {
      name: 'Hatta'
    },
    {
      name: 'Madinat Zayed'
    },
    {
      name: 'Ruwais'
    },
    {
      name: 'Liwa Oasis'
    }
  ];

  const moveTypeOptions = [
    {
      name: 'Local Move - Same City'
    },
    {
      name: 'Local Move - Same Area'
    },
    {
      name: 'Local Move - Same Building'
    },
    {
      name: 'International Move - Another Country'
    },
  ]
  const reviewIntialData = {
    name: '',
    reviewPublish: "",
    reviewDescription: "",
    Stars: 0
  }
  const [reviewData, setreviewData] = useState(reviewIntialData)

  const handleRatingChange = (newRating) => {
    setreviewData((prevData) => ({
      ...prevData,
      Stars: newRating,
    }));
  };

  const StoreOrderDetail = useSelector(selectOrderDetail)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [objectData, setObjectData] = useState(StoreOrderDetail);
  const [formMoving, setFormMoving] = useState(true);
  const [downSvg, setDownSvg] = useState(true);
  const MoveItemRef = useRef()
  const [loading, setloading] = useState(false)



  const [cityFilter, setCityFilter] = useState('');
  const [cityForm, setCityForm] = useState(false);
  const [cityDropDown, setCityDropdown] = useState(false);
  const [subCityDropDown, setSubCityDropdown] = useState(true);
  const CityRef = useRef()

  const [moveType, setMoveType] = useState(false);
  const [moveTypeDropDown, setMoveTypeDropDown] = useState(false);
  const moveTypeRef = useRef();

  const [pickUpAddress, setpickUpAddress] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(false);
  const [nameField, setNameField] = useState(false);
  const [contactField, setContactField] = useState(false);
  const [emailField, setEmailField] = useState(false);
  const [DateField, setDateField] = useState(false);
  const [formbutton, setformbutton] = useState(false);

  const [BasicForm1, setBasicForm1] = useState(true);
  const [PickUpaddressForm2, setPickUpaddressForm2] = useState(false);
  const [DeliveryaddressForm3, setDeliveryaddressForm3] = useState(false);
  const [pickupAddressformbutton, setpickupAddressformbutton] = useState(false);
  const [deliveryAddressformbutton, setdeliveryAddressformbutton] = useState(false);







  const [breadCameOpenStates, setBreadCameOpenStates] = useState(
    faq.map(() => false)
  );
  const toggleBreadCameOpen = (index) => {
    const updatedStates = [...breadCameOpenStates];
    updatedStates[index] = !updatedStates[index];
    setBreadCameOpenStates(updatedStates);
  };

  const today = new Date().toISOString().split('T')[0];







  const filteredCities = uaeCities.filter((city) =>
    city.name.toLowerCase().includes(cityFilter.toLowerCase())
  );
  return (
    <div className="pt-20">



      <div style={{
        position: "fixed", bottom: 0, zIndex: "50000000", boxShadow: `0px -5px 10px rgba(156, 236, 255, 0.8)`
      }} className="bg-white w-full sm:hidden  ">
        <div className="flex items-center justify-between gap-3 py-2 px-8 ">

          <div className="relative mx-1  rounded-3xl text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
            <a
              href="//api.whatsapp.com/send?phone=+971564683285&amp;text=Hello Sir, I want to avail Legend Movers Services"
              title="view "
              className="relative block   p-1 text-black text-green-600 hover:text-green-300  "
            >
              <span className="absolute-inset-1.5" />
              <span className="sr-only">whatsapp</span>

              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6 bi bi-whatsapp" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
              </svg>                                    </a>

          </div>
          <div className="relative mx-1  rounded-3xl p-1 text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">

            <a
              href="tel:+971567560210"
              title="view "
              className="relative block   p-1 text-black   "
            >
              <span className="absolute-inset-1.5" />
              <span className="sr-only">Call</span>

              <PhoneIcon className="h-6 w-6" aria-hidden="true" />
            </a>

          </div>
          <div className="relative  mx-1  rounded-3xl p-1 text-center focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800   ">
            <a
              href="mailto:support@legendmovers.co?subject='Contact to Legend Mover through Web Email'&body='I want to Avail your services'"
              title="Send Email"

              className="relative block   p-1 text-black   "
            >
              <span className="absolute-inset-1.5" />
              <span className="sr-only">Send Email</span>
              <EnvelopeIcon className="h-6 w-6" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>

      <div className="bg-white ">

        <div className={` w-full  ${style.BackgroundImage}   flex items-start py-8 justify-center `}>



          {BasicForm1 && <form onSubmit={(e) => {
            e.preventDefault();
            setBasicForm1(false)
            setPickUpaddressForm2(true)

            dispatch(addData({
              ...StoreOrderDetail,
              ...objectData
            }));


          }} style={{ boxShadow: ` 3px 3px 7px rgba(0, 0, 0, 0.2)` }} className={` relative  bg-gray-100 rounded-md w-10/12 md:w-6/12   mx-auto px-2  py-2 `}>
            <h2 className="text-sm font-bold text-center">Compare & Book Movers Online in 2 Minutes</h2>


            {/*1 Moves Items Form */}
            <div onClick={() => { setFormMoving(!formMoving) }} style={{ boxShadow: ` 2px 2px 4px rgba(0, 0, 0, 0.2)` }} className="flex cursor-pointer items-center rounded-sm bg-white px-2 my-2  py-2 justify-between">
              <h4 ref={MoveItemRef} className="text-xs font-medium">what are you moving?</h4>
              <div className="">
                <span className="cursor-pointer">
                  <ChevronUpDownIcon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
            </div>


            {/* 11 Moves Items DropDown */}
            {formMoving && <div className={`absolute w-11/12 px-4   mx-auto top-30 left-0 right-0 z-50 bg-white   ${formMoving ? style.slide : ''}   ${formMoving ? (` border  shahdow  `) : ''}`}>

              {/* Subfrom 1 */}
              <div className={`cursor-pointer   flex items-center  justify-between  text-cyan-400 ${formMoving ? '' : 'border rounded-lg shahdow'}`} >

                <div className="flex items-center  justify-between gap-2 py-2">
                  <img src="/moverImageDrop.png" alt="img" className="w-6 h-6 rounded-xl " />
                  <h1>MOVES    <span className=" pl-3 text-sm font-bold italic"> Apartment, Homes, Offices </span> </h1>
                </div>

                <div onClick={() => { setDownSvg(!downSvg) }}>
                  <svg
                    className={` ${downSvg ? style.rotate : style.unrotate} w-6 h-6 `}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                </div>

              </div>

              {/* subfrom 1 DropDown */}
              {downSvg && <div>
                {formRoom.map((obj, index) => {
                  return <div key={index} onClick={() => {
                    setObjectData((prevObjectData) => ({
                      ...prevObjectData,
                      movingItem: obj.name,
                    }));

                    setDownSvg(false)
                    setFormMoving(false)
                    MoveItemRef.current.innerText = obj.name

                    setCityForm(true)
                    setCityDropdown(true)
                    setSubCityDropdown(true)

                  }} className={` mt-1 px-4 cursor-pointer text-left hover:font-bold text-xs text-black  flex items-center justify-start gap-3 border-b border-gray-300 py-2`}>
                    <img src={"/" + obj.src} alt="img" className="w-4 h-4 rounded-xl " />
                    <h3>
                      {obj.name}
                    </h3>
                  </div>
                })}


              </div>}

            </div>}


            {/* 2 City Form */}
            {cityForm &&
              <div onClick={() => {
                setCityDropdown(!cityDropDown)
                setSubCityDropdown(!subCityDropDown)
              }} style={{ boxShadow: ` 2px 2px 4px rgba(0, 0, 0, 0.2)` }} className="flex items-center rounded-sm bg-white px-2 my-2  py-2 justify-between">
                <h4 ref={CityRef} className="text-xs font-medium">Select Origin City</h4>
                <div className="">
                  <span className="cursor-pointer">
                    <ChevronUpDownIcon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
              </div>}


            {/*22 city DropDown */}
            {cityDropDown && <div className={`absolute w-11/12 px-4   mx-auto top-30 left-0 right-0 z-50 bg-white   ${cityDropDown ? style.slide : ''}   ${cityDropDown ? (` border  shahdow   `) : ''}`}>

              {/* Subfrom 1 */}
              <div className={`cursor-pointer   flex items-center  justify-between gap-3  text-cyan-400 ${cityDropDown ? '' : ' '}`} >

                <div className="flex items-center  justify-between   gap-2 py-2 w-full ">
                  <input
                    value={cityFilter}
                    onChange={(e) => setCityFilter(e.target.value)}
                    type="search" placeholder="Search UAE City" name="" id="" className="py-1 text-sm px-3 w-full border rounded-lg  border-gray-400 focus:outline-none focus:ring-none shadow" />
                </div>

                <div onClick={() => { setSubCityDropdown(!subCityDropDown) }}>
                  <svg
                    className={` ${subCityDropDown ? style.rotate : style.unrotate} w-6 h-6 `}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                </div>

              </div>
              {/* subfrom 1 DropDown */}
              {subCityDropDown && (<div>
                {(cityFilter === '' ? uaeCities : filteredCities).map((obj, index) => {
                  return <div key={index} onClick={() => {
                    setObjectData((prevObjectData) => ({
                      ...prevObjectData,
                      city: obj.name,
                    }));

                    setCityForm(true)
                    setCityDropdown(false)
                    setSubCityDropdown(false)
                    CityRef.current.innerText = obj.name

                    setMoveType(true)
                    setMoveTypeDropDown(true)


                  }} className={` mt-1 px-4 cursor-pointer text-left hover:font-bold text-xs text-black  flex items-center justify-start gap-3 border-b border-gray-300 py-2`}>

                    <h3>
                      {obj.name}
                    </h3>
                  </div>
                })}


              </div>)}

            </div>}


            {/*  3 Move Type Form */}
            {moveType &&
              <div onClick={() => {
                setMoveTypeDropDown(!moveTypeDropDown)
              }} style={{ boxShadow: ` 2px 2px 4px rgba(0, 0, 0, 0.2)` }} className="flex items-center rounded-sm bg-white px-2 my-2  py-2 justify-between">
                <h4 ref={moveTypeRef} className="text-xs font-medium">Select Move Type</h4>
                <div className="">
                  <span className="cursor-pointer">
                    <ChevronUpDownIcon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
              </div>}


            {/*33 moveType DropDown */}
            {moveTypeDropDown && <div className={`absolute w-11/12 px-4   mx-auto top-30 left-0 right-0 z-50 bg-white   ${moveTypeDropDown ? style.slide : ''}   ${moveTypeDropDown ? (` border  shahdow   `) : ''}`}>

              <div>
                {moveTypeOptions.map((obj, index) => {
                  return <div key={index} onClick={() => {
                    setObjectData((prevObjectData) => ({
                      ...prevObjectData,
                      OptionMovetype: obj.name,
                    }));

                    setMoveTypeDropDown(true)
                    setMoveTypeDropDown(false)
                    moveTypeRef.current.innerText = obj.name

                    setpickUpAddress(true)

                  }} className={` mt-1 px-4 cursor-pointer text-left hover:font-bold text-xs text-black  flex items-center justify-start gap-3 border-b border-gray-300 py-2`}>

                    <h3>
                      {obj.name}
                    </h3>
                  </div>
                })}
              </div>

            </div>}

            {/*4 piclkup Address  */}
            {pickUpAddress && <div>
              <h2 className="font-semibold text-xs my-1 ">Pickup Address - Building/Area Name</h2>
              <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">
                <MapPinIcon className="h-4 w-4" aria-hidden="true" />

                <input
                  required

                  type="text" value={objectData.pickUpaddress1} onChange={(e) => {
                    setObjectData((prevObjectData) => ({
                      ...prevObjectData,
                      pickUpaddress1: e.target.value,
                    }))
                    setTimeout(() => {
                      setDeliveryAddress(true)
                    }, 3000);
                  }} placeholder="Enter Complete PickUp Address " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                />
                {/* <button
                  // style={{ cursor: "not-allowed" }}
                  type="submit"
                  disabled={deliveryAddress}
                  className={`bg-green-500 text-white px-2 rounded-md ${deliveryAddress ? 'cursor-not-allowed bg-green-200' : ''} `}>Proceed</button> */}
              </div>
            </div>}

            {/*4 delivery address*/}
            {deliveryAddress && <div>
              <h2 className="font-semibold text-xs my-1 ">Delivery Address - Building/Area Name</h2>
              <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">
                <MapPinIcon className="h-4 w-4" aria-hidden="true" />

                <input
                  required

                  type="text" value={objectData.deliveryAddress1} onChange={(e) => {
                    setObjectData((prevObjectData) => ({
                      ...prevObjectData,
                      deliveryAddress1: e.target.value,
                    }))
                    setTimeout(() => {
                      setNameField(true)
                    }, 3000);

                  }} placeholder="Enter Complete Delivery Address " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                />
              </div>
            </div>}

            {/*4 name field */}
            {nameField && <div className="flex items-center justify-between gap-2">
              <div>
                <h2 className="font-semibold text-xs my-1 ">First Name</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                  </svg>

                  <input
                    required

                    type="text" value={objectData.firstName} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        firstName: e.target.value,
                      }))


                    }} placeholder="Enter First Name " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>

              <div>
                <h2 className="font-semibold text-xs my-1 ">Last Name</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                  </svg>

                  <input
                    required

                    type="text" value={objectData.lastName} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        lastName: e.target.value,
                      }))
                      setTimeout(() => {
                        setContactField(true)
                      }, 3000);

                    }} placeholder="Enter Last Name" name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>
            </div>}

            {/* cotact feild */}
            {contactField &&
              <div className="w-full mx-auto">
                <label htmlFor="contact" className="block text-sm font-bold leading-6 text-gray-900">
                  Contact
                </label>

                <div className="my-2 ">

                  <Select required
                    onChange={(selectedCoutry) => {

                      setObjectData((prevData) => ({
                        ...prevData,
                        countrycode: `+${selectedCoutry.value}`
                      }));
                    }}
                    className={` w-full rounded-lg  text-center `} options={options}
                  />
                </div>

                <div className=" w-full flex items-center  rounded-md border-gray-400 justify-between gap-3 " >
                  {objectData.countrycode &&

                    <input
                      type="text"
                      disabled
                      value={objectData.countrycode}
                      className="block text-center px-2 py-2 bg-gray-300 rounded-lg w-20  "

                    />
                  }

                  <input
                    id="contact"
                    name="contact2"
                    type="text"
                    placeholder="3xx xxxxxx"
                    autoComplete="adress"
                    required
                    value={objectData.contact2}
                    onChange={(selectedCoutry) => {

                      setObjectData((prevData) => ({
                        ...prevData,
                        contact2: selectedCoutry.target.value
                      }));
                      setTimeout(() => {
                        setEmailField(true)
                      }, 3000);

                    }}
                    className="block px-2 w-full rounded-md border-0  py-2 ring-1 ring-inset ring-gray-300  text-gray-900 shadow-sm placeholder:text-gray-400  focus:ring-none sm:text-sm sm:leading-6"
                  />

                </div>


              </div>
            }

            {/*4 search buildind */}
            {emailField && <div>
              <h2 className="font-semibold text-xs my-1 ">Enter Email Address</h2>
              <div className={`bg-white px-2  shadow-md flex items-center justify-center gap-3  `}>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>

                <input
                  required

                  type="text" value={objectData.Useremail} onChange={(e) => {
                    setObjectData((prevObjectData) => ({
                      ...prevObjectData,
                      Useremail: e.target.value,
                    }))
                    setTimeout(() => {
                      setDateField(true)
                    }, 3000);


                  }}
                  placeholder="Enter Complete Delivery Address " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                />

              </div>
            </div>}

            {/*4 clender buildind */}
            {DateField && <div className="grid grid-cols-2  gap-2">
              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">Move  Time</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>

                  <input
                    required
                    type="time" value={objectData.moveTime} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        moveTime: e.target.value,
                      }))


                    }} placeholder="Enter First Name " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>

              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">Move Date</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                  </svg>

                  <input
                    required
                    min={today}
                    type="date" value={objectData.moveDate} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        moveDate: e.target.value,
                      }))
                      setTimeout(() => {
                        setformbutton(true)
                      }, 3000);

                    }} placeholder="Enter Last Name" name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>
            </div>}

            {formbutton && <button type="submit" className="w-full my-2 p-2 text-center shadow hover:shadow-none border-0 rounded-lg bg-cyan-300 ">
              Proceed
            </button>}

          </form>}

          {/* home form Section  */}
          {PickUpaddressForm2 && <form onSubmit={(e) => {
            e.preventDefault();
            setPickUpaddressForm2(false)
            setDeliveryaddressForm3(true)
            dispatch(addData({
              ...StoreOrderDetail,
              ...objectData
            }));

          }} style={{ boxShadow: ` 3px 3px 7px rgba(0, 0, 0, 0.2)` }} className={` relative  bg-gray-100 rounded-md w-10/12 md:w-6/12   mx-auto px-2  py-4 `}>
            <h2 className="text-sm font-bold text-center">Please Confirm Your Pickup Address Details</h2>

            <div>
              <h2 className="font-semibold text-xs my-1 ">Pickup Address - Building/Area Name</h2>
              <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">
                <MapPinIcon className="h-4 w-4" aria-hidden="true" />

                <input
                  required

                  type="text" value={objectData.pickUpaddress1} onChange={(e) => {
                    setObjectData((prevObjectData) => ({
                      ...prevObjectData,
                      pickUpaddress1: e.target.value,
                    }))

                  }} placeholder="Enter Complete PickUp Address " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                />
              </div>
            </div>



            {/*4 clender buildind */}
            <div className="grid grid-cols-3 place-items-center  gap-2">
              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">Area</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">


                  <input
                    required
                    type="text" value={objectData.pickUpArea} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        pickUpArea: e.target.value,
                      }))


                    }} placeholder="Enter Area " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>

              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">  City</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                  </svg>


                  <input
                    required
                    min={today}
                    type="text" value={objectData.PickUpCity} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        PickUpCity: e.target.value,
                      }))


                    }} placeholder="Enter City" name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>
              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">  state</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                  </svg>


                  <input
                    required
                    min={today}
                    type="text" value={objectData.PickUpState} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        PickUpState: e.target.value,
                      }))


                    }} placeholder="Enter State" name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-5   gap-2">
              <div className="col-span-2">
                <h2 className="font-semibold text-xs my-1 ">Building Name</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                  </svg>


                  <input
                    required
                    type="text" value={objectData.PickUpBuildingdName} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        PickUpBuildingdName: e.target.value,
                      }))


                    }} placeholder="Enter Building Name " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>


              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">  Unit Number</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                  </svg>


                  <input
                    required
                    min={today}
                    type="Number" value={objectData.PickUpBuildingUnitNumber} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        PickUpBuildingUnitNumber: e.target.value,
                      }))


                    }} placeholder="Enter City" name="" id="" className="bg-none py-1 text-sm  w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>

              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">  Floor Number</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                  </svg>


                  <input
                    required
                    min={today}
                    type="number" value={objectData.PickUpBuildingFloorNumber} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        PickUpBuildingFloorNumber: e.target.value,
                      }))

                    }} placeholder="Enter Floor Number" name="" id="" className="bg-none py-1 text-sm  w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>

              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">  Service Lift </h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <div className="flex items-center justify-between gap-1 py-1" >
                    <p className="text-xs font-bold">
                      Yes
                    </p>
                    <input
                      required
                      min={today}
                      type="radio" value={objectData.PickUpBuildingServiceLift} onChange={(e) => {
                        setObjectData((prevObjectData) => ({
                          ...prevObjectData,
                          PickUpBuildingServiceLift: true,
                        }))

                      }} placeholder="Enter City" name="liftRadio" id="liftRadio2" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                    />
                  </div>
                  <div className="flex items-center justify-between gap-1" >
                    <p className="text-xs font-bold">
                      No
                    </p>
                    <input
                      required
                      min={today}
                      type="radio" value={objectData.PickUpBuildingServiceLift} onChange={(e) => {
                        setObjectData((prevObjectData) => ({
                          ...prevObjectData,
                          PickUpBuildingServiceLift: false,
                        }))

                      }} placeholder="Enter City" name="liftRadio" id="liftRadio" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                    />
                  </div>


                </div>
              </div>
            </div>

            <div>
              <h2 className="font-semibold text-xs my-1 ">Distance from Truck Parking to Pickup Point in Meters?</h2>
              <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">
                <MapPinIcon className="h-4 w-4" aria-hidden="true" />

                <input
                  required

                  type="text" value={objectData.DistancePickupPoint} onChange={(e) => {
                    setObjectData((prevObjectData) => ({
                      ...prevObjectData,
                      DistancePickupPoint: e.target.value,
                    }))

                    setTimeout(() => {
                      setpickupAddressformbutton(true)
                    }, 3000);

                  }} placeholder="Distance from Truck Parking to Pickup Point in Meters? " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                />
              </div>
            </div>


            {pickupAddressformbutton && <button type="submit" className="w-full my-2 p-2 text-center shadow hover:shadow-none border-0 rounded-lg bg-cyan-300 ">
              Next
            </button>}


          </form>}


          {/* home form Section  */}
          {DeliveryaddressForm3 && <form onSubmit={(e) => {
            e.preventDefault();
            dispatch(addData({
              ...StoreOrderDetail,
              ...objectData
            }));
            navigate("/order/Location")
          }} style={{ boxShadow: ` 3px 3px 7px rgba(0, 0, 0, 0.2)` }} className={` relative  bg-gray-100 rounded-md w-10/12 md:w-6/12   mx-auto px-2  py-4 `}>
            <h2 className="text-sm font-bold text-center">Please Confirm Your Delivery Address Details</h2>

            <div>
              <h2 className="font-semibold text-xs my-1 ">Delivery Address - Building/Area Name</h2>
              <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">
                <MapPinIcon className="h-4 w-4" aria-hidden="true" />

                <input
                  required

                  type="text" value={objectData.deliveryAddress1} onChange={(e) => {
                    setObjectData((prevObjectData) => ({
                      ...prevObjectData,
                      deliveryAddress1: e.target.value,
                    }))

                  }} placeholder="Enter Complete PickUp Address " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                />
              </div>
            </div>



            {/*4 clender buildind */}
            <div className="grid grid-cols-3 place-items-center  gap-2">
              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">Area</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">


                  <input
                    required
                    type="text" value={objectData.DeliveryArea} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        DeliveryArea: e.target.value,
                      }))


                    }} placeholder="Enter Area " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>

              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">  City</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                  </svg>


                  <input
                    required
                    min={today}
                    type="text" value={objectData.DeliveryCity} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        DeliveryCity: e.target.value,
                      }))


                    }} placeholder="Enter City" name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>
              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">  state</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                  </svg>


                  <input
                    required
                    min={today}
                    type="text" value={objectData.DeliveryState} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        DeliveryState: e.target.value,
                      }))


                    }} placeholder="Enter State" name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-5   gap-2">
              <div className="col-span-2">
                <h2 className="font-semibold text-xs my-1 ">Building Name</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                  </svg>


                  <input
                    required
                    type="text" value={objectData.DeliveryBuildingdName} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        DeliveryBuildingdName: e.target.value,
                      }))


                    }} placeholder="Enter Building Name " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>


              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">  Unit Number</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                  </svg>


                  <input
                    required
                    min={today}
                    type="Number" value={objectData.DeliveryBuildingUnitNumber} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        DeliveryBuildingUnitNumber: e.target.value,
                      }))


                    }} placeholder="Enter City" name="" id="" className="bg-none py-1 text-sm  w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>

              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">  Floor Number</h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                  </svg>


                  <input
                    required
                    min={today}
                    type="number" value={objectData.DeliveryBuildingFloorNumber} onChange={(e) => {
                      setObjectData((prevObjectData) => ({
                        ...prevObjectData,
                        DeliveryBuildingFloorNumber: e.target.value,
                      }))

                    }} placeholder="Enter Floor Number" name="" id="" className="bg-none py-1 text-sm  w-full focus:outline-none focus:ring-none "
                  />
                </div>
              </div>

              <div className="col-span-1">
                <h2 className="font-semibold text-xs my-1 ">  Service Lift </h2>
                <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">

                  <div className="flex items-center justify-between gap-1 py-1" >
                    <p className="text-xs font-bold">
                      Yes
                    </p>
                    <input
                      required
                      min={today}
                      type="radio" value={objectData.DeliveryBuildingServiceLift} onChange={(e) => {
                        setObjectData((prevObjectData) => ({
                          ...prevObjectData,
                          DeliveryBuildingServiceLift: true,
                        }))

                      }} placeholder="Enter City" name="liftRadio" id="liftRadio2" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                    />
                  </div>
                  <div className="flex items-center justify-between gap-1" >
                    <p className="text-xs font-bold">
                      No
                    </p>
                    <input
                      required
                      min={today}
                      type="radio" value={objectData.DeliveryBuildingServiceLift} onChange={(e) => {
                        setObjectData((prevObjectData) => ({
                          ...prevObjectData,
                          DeliveryBuildingServiceLift: false,
                        }))

                      }} placeholder="Enter City" name="liftRadio" id="liftRadio" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                    />
                  </div>


                </div>
              </div>
            </div>

            <div>
              <h2 className="font-semibold text-xs my-1 ">Distance from Truck Parking to Delivery Point in Meters?</h2>
              <div className="bg-white px-2  shadow-md flex items-center justify-center gap-3">
                <MapPinIcon className="h-4 w-4" aria-hidden="true" />

                <input
                  required

                  type="text" value={objectData.DistanceDeliveryPoint} onChange={(e) => {
                    setObjectData((prevObjectData) => ({
                      ...prevObjectData,
                      DistanceDeliveryPoint: e.target.value,
                    }))

                    setTimeout(() => {
                      setdeliveryAddressformbutton(true)
                    }, 3000);

                  }} placeholder="Distance from Truck Parking to Pickup Point in Meters? " name="" id="" className="bg-none py-1 text-sm px-3 w-full focus:outline-none focus:ring-none "
                />
              </div>
            </div>


            {deliveryAddressformbutton && <button type="submit" className="w-full my-2 p-2 text-center shadow hover:shadow-none border-0 rounded-lg bg-cyan-300 ">
              Next
            </button>}


          </form>}

        </div>
      </div>





      {/* How It Works? */}
      <div className="bg-white">
        <div className=" w-11/12 mx-auto py-10 px-4  ">
          <h1 className=" text-center font-semibold text-3xl ">
            How It Works?
          </h1>

          <div className="grid grid-cols-4  gap-4 my-8">
            <div className="col-span-2 md:col-span-1   py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/plan.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">Plan</h1>
            </div>
            <div className="col-span-2 md:col-span-1     py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/compare.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">Compare</h1>
            </div>
            <div className="col-span-2  md:col-span-1  py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/booking.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">Book</h1>
            </div>
            <div className="col-span-2 md:col-span-1    py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/s1.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">Online</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Get Quotes For All Your Moving Needs */}
      <div style={{ backgroundColor: "#f9fbff" }}>
        <div className="  py-10 px-4 w-11/12 mx-auto  ">
          <h1 className=" text-center font-semibold text-3xl ">
            Get Quotes For All Your Moving Needs
          </h1>

          <div className="grid grid-cols-4 gap-4 my-8">
            <div className="col-span-2 md:col-span-1    py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/local-move.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                Local Move
              </h1>
            </div>
            <div className="col-span-2 md:col-span-1   py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/domestic-move.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                Domestic Move
              </h1>
            </div>
            <div className="col-span-2 md:col-span-1    py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/international.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                International Move
              </h1>
            </div>
            <div className="col-span-2 md:col-span-1   py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/s2.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                Self Storage
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* why choose us */}
      <div className="bg-white">
        <div className="bg-white py-10 px-4 w-11/12 mx-auto ">
          <h1 className=" text-center font-semibold text-3xl ">
            Why Choose Us?
          </h1>
          <p className=" text-center font-light text-base my-1">
            Legend Movers is a synonym for Savings, Convenience, Security, Happy
            Moving!!
          </p>

          <div className="grid grid-cols-3  gap-4 my-8">
            <div className="col-span-3 md:col-span-1 px-2   py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/local-move.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                HUGE SAVINGS
              </h1>
              <p>Book online and save 45% on your moving costs on average.</p>
            </div>
            <div className="col-span-3 md:col-span-1  px-2   py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/domestic-move.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                PAYMENT PROTECTION
              </h1>
              <p>
                You don't pay the movers. We pay the movers after your move is
                completed.
              </p>
            </div>
            <div className="col-span-3 md:col-span-1 px-2   py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/international.svg"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                RELIABILITY
              </h1>
              <p>
                We verify the licenses, insurance, and reviews of every moving
                company.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Our Services */}
      <div style={{ backgroundColor: "#f9fbff" }}>
        <div className="  py-10 px-4 w-11/12 mx-auto  ">
          <h1 className=" text-center font-semibold text-3xl ">Our Services</h1>
          <p className="text-center font-light text-base my-1">
            All services at one stop
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 my-8">
            <div className=" col-span-2 sm:col-span-1  md:col-span-1  py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/houseMoving.png"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                House moving
              </h1>
            </div>
            <div className=" col-span-2 sm:col-span-1  md:col-span-1  py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/officeMoving.png"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                Office Moving{" "}
              </h1>
            </div>
            <div className=" col-span-2 sm:col-span-1  md:col-span-1  py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/storageImg.png"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                Storage services
              </h1>
            </div>
            <div className=" col-span-2 sm:col-span-1  md:col-span-1  py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/paintImg.png"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                Painting services
              </h1>
            </div>
            <div className=" col-span-2 sm:col-span-1  md:col-span-1  py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/technicalImg.png"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                Technical work
              </h1>
            </div>
            <div className=" col-span-2 sm:col-span-1  md:col-span-1  py-8 border shadow-sm rounded-lg hover:border-cyan-400 ">
              <div className="flex items-center justify-center ">
                <img
                  src="/cleaningImg.png"
                  className="w-20 h-20"
                  alt="Plan in United Arab Emirates"
                />
              </div>
              <h1 className="text-center my-4 font-normal text-2xl">
                Cleaning services
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className=" py-10 px-4 w-11/12 mx-auto flex items-center justify-center sm:justify-between flex-wrap ">
          <div className="md:w-5/12 w-full mx-auto flex items-center justify-center md:justify-start ">
            <img src="/reviews.jpg" alt="img" className="w-80 h-80" />
          </div>
          <form onSubmit={async (e) => {
            e.preventDefault()

            if (!(reviewData.Stars === 0)) {
              try {
                setloading(true)
                const response = await axios.post(`https://confused-crow-windbreaker.cyclic.app/api/add_reviews`, reviewData);
                if (response) {

                  if (response.status === 200) {

                    toast.success("Thank your for your Feedback :)")
                    setloading(false)
                    dispatch(getReviews(response.data.Allreviews))
                  }
                }
              } catch (error) {
                setloading(false)
                toast.error("Try again later")
              }
              setreviewData(reviewIntialData)
            } else {
              toast.error("Please Add Rating Stars")
            }
          }} className="md:w-5/12 w-full">
            <h2 className="font-bold text-center text-2xl">
              Add a review
            </h2>
            <div className="grid grid-cols-2 gap-2 my-4 ">
              <h1 className="font-semibold text-lg">
                Name:
              </h1>
              <input required type="text" placeholder="Enter your Name" className="bg-gray-100 shadow p-2 rounded-lg border focus:outline-none focus:ring-0 " name="name" id="name" value={reviewData.name} onChange={(e) => {
                setreviewData((predata) => ({ ...predata, name: e.target.value }))
              }} />
            </div>
            <div className="grid grid-cols-2 gap-2 my-4 ">
              <h1 className="font-semibold text-lg">
                Message:
              </h1>
              <textarea required rows={3} type="text" placeholder="Enter your Message" className="bg-gray-100 shadow p-2 rounded-lg border focus:outline-none focus:ring-0 " name="ReviewDescription" id="name" value={reviewData.reviewDescription} onChange={(e) => {
                setreviewData((predata) => ({ ...predata, reviewDescription: e.target.value }))
              }} />
            </div>
            <div className="grid grid-cols-2 gap-2 my-4 ">
              <h1 className="font-semibold text-lg">
                Rate us:
              </h1>
              <div>
                <input value={reviewData.Stars} disabled type="text" placeholder="Rating" className=" w-full bg-gray-100 shadow p-2 rounded-lg border focus:outline-none focus:ring-0 " name="name" id="name"
                />
                <div className="flex items-center justify-between gap-2">
                  {[1, 2, 3, 4, 5].map((rating, index) => (
                    <div
                      key={index}
                      onClick={() => handleRatingChange(rating)}
                      className={`cursor-pointer text-center     text-2xl ${rating <= reviewData.rating ? 'text-yellow-500' : 'text-gray-300'
                        }`}
                    >
                      <div>

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#f4dc03" width={"30px"} height={"30px"}>
                          <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clipRule="evenodd" />
                        </svg>
                      </div>
                      <div className="font-bold text-sm">

                        {index + 1}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end">

              <button className="font-bold px-6 py-2 rounded-lg bg-green-500 text-white shadow hover:shadow-none ">
                Add Review
              </button>
            </div>
          </form>
        </div>
      </div>

      {storeReviews.length > 0 &&

        <div className="bg-gray-100">
          <div className="  py-10 px-2  w-11/12 mx-auto  ">
            <h1 className="font-bold text-3xl text-center my-6">
              What our customers say
            </h1>
            <ReviewsSwipper />
          </div>
        </div>}

      {/* FAQ */}
      <div className="bg-white ">
        <div className=" py-10 px-4 w-11/12 mx-auto ">
          <h2 className="text-4xl text-center font-semibold my-1">
            Got Questions?
          </h2>
          <div className={`grid grid-cols-2 gap-4 my-6 px-2 py-2  `}>
            {faq.map((breadobject, index) => {
              return (
                <div key={index} className={`col-span-2 md:col-span-1 ${breadCameOpenStates[index] ? style.slide : ''}   ${breadCameOpenStates[index] ? (` border rounded-lg shahdow`) : ''}`}>

                  <div
                    onClick={() => toggleBreadCameOpen(index)}
                    className={`cursor-pointer flex items-center px-8  justify-between py-6 px-1 hover:text-cyan-400 ${breadCameOpenStates[index] ? '' : 'border rounded-lg shahdow'}`}
                  >
                    <h1>{breadobject.name}</h1>
                    <div>
                      <svg
                        className={` ${breadCameOpenStates[index] ? style.rotate : style.unrotate} w-6 h-6 `}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 15.75l7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    </div>
                  </div>
                  {breadCameOpenStates[index] && (
                    <div className={` py-4 px-8 text-left text-gray-500 `}>
                      <p >
                        {breadobject.discription}
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>


      {/* <div className="bg-white">
        <div className=" py-10 px-4 w-11/12 mx-auto  " >
          <ReviewsWidget /> 

        </div>
      </div> */}

      <div style={{ backgroundColor: "#f9fbff" }}>

        {/* country Guide */}
        <div className="bg-white ">
          <div className=" py-10 px-4 w-11/12 mx-auto  " >

            <h2 className="text-2xl text-left font-semibold my-1">
              Country Guide for United Arab Emirates
            </h2>
            <p className="my-1 text-lg  ">
              The United Arab Emirates (UAE) is a country located in the Middle East, bordering the Gulf of Oman and the Persian Gulf. It is a federation of seven emirates, each with its own ruler. The UAE is known for its rich culture, modern infrastructure, and vibrant economy. It is also home to some of the world's most luxurious hotels and resorts.

              <br />
              <br />
              The UAE is a great place to live and work, with a high standard of living and a wide range of job opportunities. The country is also home to some of the world's most iconic landmarks, such as the Burj Khalifa, the world's tallest building, and the Palm Jumeirah, an artificial archipelago. The UAE is also known for its vibrant nightlife, with a wide range of bars, clubs, and restaurants.
              <br />
              <br />
              Moving to the UAE can be a daunting task, but with the help of Legend Movers, you can make the process easier. Legend Movers can help you find the best moving companies in the UAE, compare prices, and book your move. Legend Movers also offers a range of services to make your move easier, such as packing and unpacking, storage, and insurance.
              <br />
              <br />
              Legend Movers can also help you find the best places to live in the UAE, with detailed information on neighborhoods, schools, and amenities. Legend Movers can also help you find the best deals on housing, with access to a wide range of rental properties and real estate agents.
              <br />
              <br />
              Whether you're moving to the UAE for work or pleasure, Legend Movers can help make the process easier. With its comprehensive range of services and resources, Legend Movers can help you make the most of your move to the UAE.
            </p>

            <h2 className="text-2xl text-left font-semibold my-1">
              United Arab Emirates Custom Duties Guide and Paperwork Required While Moving to United Arab Emirates
            </h2>

            <p className="my-2 text-lg  ">
              Moving to the United Arab Emirates (UAE) can be a daunting task, especially when it comes to understanding the customs duties and paperwork required. This guide will provide an overview of the customs duties and paperwork required when moving to the UAE, as well as some frequently asked questions (FAQs). Additionally, we will explain how Legend Movers can help you manage your move to the UAE.
            </p>
            <h2 className="text-2xl text-left font-semibold my-1">
              Customs Duties
            </h2>

            <p className="my-2 text-lg  ">
              When moving to the UAE, you will be required to pay customs duties on certain items. The customs duties are based on the value of the items being imported, and the rate of duty varies depending on the type of item. Generally, the customs duties range from 0-7%. However, some items may be exempt from customs duties, such as books, magazines, and certain medical supplies.
            </p>
            <h2 className="text-2xl text-left font-semibold my-1">
              Paperwork Required
            </h2>

            <p className="my-2 text-lg  ">
              When moving to the UAE, you will need to provide certain documents to the customs authorities. These documents include a valid passport, a visa, a customs declaration form, and a packing list. Additionally, you may need to provide proof of ownership for certain items, such as a bill of sale or a receipt.
            </p>
            <h2 className="text-2xl text-left font-semibold my-1">
              FAQs
            </h2>

            <ul className="my-2 text-lg  px-12 ">
              <li style={{ listStyleType: "disc" }} >
                What items are subject to customs duties?

              </li>
              <p>
                Generally, items such as electronics, furniture, and clothing are subject to customs duties. However, some items may be exempt from customs duties, such as books, magazines, and certain medical supplies.
                Do I need to provide proof of ownership for my items?
              </p>
              <li style={{ listStyleType: "disc" }} >
                Do I need to provide proof of ownership for my items?
              </li>
              <p>
                Yes, you may need to provide proof of ownership for certain items, such as a bill of sale or a receipt.
              </p>
              <li style={{ listStyleType: "disc" }} >
                What documents do I need to provide to the customs authorities?
              </li>
              <p>
                You will need to provide a valid passport, a visa, a customs declaration form, and a packing list.
              </p>
            </ul>

            <h2 className="text-2xl text-left font-semibold my-1">
              How Legend Movers Can Help
            </h2>

            <p className="my-2 text-lg  ">
              Legend Movers can help you manage your move to the UAE by providing you with a comprehensive list of services. Our services include packing and unpacking, loading and unloading, storage, and transportation. Additionally, we can help you with the paperwork required for customs clearance, as well as provide you with advice on the customs duties and regulations. With Legend Movers, you can rest assured that your move to the UAE will be stress-free and hassle-free.
            </p>


          </div>
        </div>

        {/* pattner APP links */}
        <div style={{ backgroundColor: "#f9fbff" }} className="flex items-center justify-center gap-3  py-10 px-4 w-11/12 mx-auto  ">
          <div>
            <h2 className="text-xl text-left font-semibold my-2">
              Become Legend Movers Partner
            </h2>
            <p className="text-medium text-left font-ligjt mt-3 mb-6">
              We believe in doing right by our service providers. We bridge the gap between customers and you, to make moving a Jolly ride for all.
            </p>

            <div className="grid grid-cols-3 gap-2  w-8/12 mt-2 ">
              <div className="col-span-1">

                <img src="/google.svg" alt="img" className=" w-fit h-fit" />
              </div>
              <div className="col-span-1">

                <img src="/app.svg" alt="img" className=" w-fit h-fit" />
              </div>
              <div className="col-span-1">

                <img src="/partner.svg" alt="img" className=" w-fit h-fit" />
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <img src="/partner.png" alt="img" />
          </div>
        </div>
      </div>
      <Loader loading={loading} />
    </div >
  );
}
// {breadCameOpenStates[index] ?
//   <svg
//     className={`  w-6 h-6 `}
//     xmlns="http://www.w3.org/2000/svg"
//     fill="none"
//     viewBox="0 0 24 24"
//     strokeWidth={1.5}
//     stroke="currentColor"
//   >
//     <path
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       d="M4.5 15.75l7.5-7.5 7.5 7.5"
//     />
//   </svg>
// :
//   <svg
//     className={`  w-6 h-6`}
//     xmlns="http://www.w3.org/2000/svg"
//     fill="none"
//     viewBox="0 0 24 24"
//     strokeWidth={1.5}
//     stroke="currentColor"
//   >
//     <path
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       d="M19.5 8.25l-7.5 7.5-7.5-7.5"
//     />
//   </svg>
// }