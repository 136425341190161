import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  OrderDetails: {
    movingItem: '',
    city: '',
    OptionMovetype: '',
    pickUpaddress1: '',
    deliveryAddress1: '',
    firstName: '',
    lastName: '',
    contact2: '',
    countrycode: '',
    Useremail: '',
    moveTime: '',
    moveDate: '',
    pickUpArea: '',
    PickUpCity: '',
    PickUpState: '',
    PickUpBuildingdName: '',
    PickUpBuildingUnitNumber: 0,
    PickUpBuildingFloorNumber: '',
    DistancePickupPoint: '',
    PickUpBuildingServiceLift: false,
    DeliveryArea: '',
    DeliveryCity: '',
    DeliveryState: '',
    DeliveryBuildingdName: '',
    DeliveryBuildingUnitNumber: 0,
    DeliveryBuildingFloorNumber: '',
    DistanceDeliveryPoint: '',
    DeliveryBuildingServiceLift: false,
    cartItems: []

  },
  ReviewsState: [ ]
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addData: (state, action) => {
      state.OrderDetails = action.payload;
    },
    getReviews: (state, action) => {
      state.ReviewsState = action.payload;
    },




  },

});

export const selectOrderDetail = (state) => state.auth.OrderDetails;
export const selectReviews = (state) => state.auth.ReviewsState;

export const { addData, getReviews } = authSlice.actions;

export default authSlice.reducer;
